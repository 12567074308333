var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSetting } from 'src/hooks/api';
import SettingsState from 'src/hooks/state/Settings';
import { CUSTOMIZE_MOBILE_SHIFT_CARD, SHIFT_RECOMMENDATIONS_FEATURE, } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { useRecoilValue } from 'recoil';
import { CollectionType } from 'src/shared';
import { ForbiddenCustomizableShiftSchemas, MobileClockOutFieldKeys, MobilePermissionDetails, MobileRequiredFieldKeys, } from '../../../../Constants';
import AccessDetailHelperBanner from '../AccessDetailHelperBanner';
import AccessDetailGroupChild from '../AccessDetailListItem/AccessDetailGroupChild';
import AccessDetailGroupItem from '../AccessDetailListItem/AccessDetailGroupItem';
import MobileHideOthersFieldOption from './MobileHideOthersFieldOption';
import MobileProfileLinkedOptionButton from './MobileProfileLinkedOptionButton';
import MobileRequireApprovalDropdown from './MobileRequireApprovalDropdown';
import MobileTimeInputDropdown from './MobileTimeInputDropdown';
import MobileTrackingDropdown from './MobileTrackingDropdown';
import MobileRequiredFieldsDropdown from './MobileRequiredFieldsDropdown';
import MobileCustomShownData from './MobileCustomShownData';
var MobileSettings = function () {
    var _a = SettingsState.useContainer(), accessGroupDetail = _a.accessGroupDetail, getAccessGroupDetail = _a.getAccessGroupDetail;
    var isCustomizeShiftCardMobileOn = useRecoilValue(featureEnableStatusState(CUSTOMIZE_MOBILE_SHIFT_CARD));
    var _b = useState(accessGroupDetail.mobileSettings), mobileSettings = _b[0], setMobileSettings = _b[1];
    useEffect(function () {
        setMobileSettings(accessGroupDetail.mobileSettings);
        return function () {
            getAccessGroupDetail(accessGroupDetail.id);
        };
    }, [accessGroupDetail, getAccessGroupDetail]);
    var renderSubPermissionTailChild = useCallback(function (subPermissionOption, subPermissionKey, onSettingChange, parentEnabled) {
        var _a, _b;
        //!MARK: if depends on the key, return early
        if (subPermissionKey === 'hiddenLinkedData') {
            return (_jsx(MobileProfileLinkedOptionButton, { onChange: function (options) { return __awaiter(void 0, void 0, void 0, function () {
                    var hiddenOptions;
                    return __generator(this, function (_a) {
                        hiddenOptions = options.filter(function (option) { return !option.enabled; });
                        if (hiddenOptions.length === 0)
                            return [2 /*return*/];
                        onSettingChange(__assign(__assign({}, subPermissionOption), { options: hiddenOptions }));
                        return [2 /*return*/];
                    });
                }); } }));
        }
        if (subPermissionKey === 'customizedShiftViewSchemas' && isCustomizeShiftCardMobileOn) {
            return (_jsx(MobileCustomShownData, { collectionType: CollectionType.SHIFT, fieldKeys: (_b = (_a = accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.mobileSettings.calendar.subPermissions.customizedShiftViewSchemas) === null || _a === void 0 ? void 0 : _a.values) !== null && _b !== void 0 ? _b : [], forbiddenSchemas: ForbiddenCustomizableShiftSchemas, accessGroupId: accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.id, onChange: function (values) {
                    onSettingChange({
                        values: values,
                    });
                } }));
        }
        if ('requireApproval' in subPermissionOption && 'value' in subPermissionOption) {
            return (_jsxs("div", { className: "flex space-x-1.5", children: [_jsx(MobileRequireApprovalDropdown, { defaultRequired: subPermissionOption.requireApproval, onChange: function (requireApproval) {
                            onSettingChange(__assign(__assign({}, subPermissionOption), { requireApproval: requireApproval }));
                        } }), _jsx(MobileTimeInputDropdown, { defaultTime: subPermissionOption.value, onChange: function (value) {
                            onSettingChange(__assign(__assign({}, subPermissionOption), { value: value }));
                        }, showPreSelection: true, disableByDefault: parentEnabled })] }));
        }
        if ('requireApproval' in subPermissionOption) {
            return (_jsx(MobileRequireApprovalDropdown, { defaultRequired: subPermissionOption.requireApproval, onChange: function (requireApproval) {
                    onSettingChange(__assign(__assign({}, subPermissionOption), { requireApproval: requireApproval }));
                } }));
        }
        if ('value' in subPermissionOption) {
            return (_jsx(MobileTimeInputDropdown, { defaultTime: subPermissionOption.value, onChange: function (value) {
                    onSettingChange(__assign(__assign({}, subPermissionOption), { value: value }));
                }, showPreSelection: subPermissionKey !== 'earlyClockIn', disableByDefault: parentEnabled }));
        }
        if ('values' in subPermissionOption) {
            if (subPermissionKey === 'hideOthersShiftSchemas') {
                return (_jsx(MobileHideOthersFieldOption, { onChange: function (values) {
                        onSettingChange({
                            values: values,
                        });
                    } }));
            }
            else {
                return (_jsx(MobileRequiredFieldsDropdown, { type: MobileClockOutFieldKeys.includes(subPermissionKey)
                        ? 'CLOCK_OUT'
                        : 'CLOCK_IN', rule: MobileRequiredFieldKeys.includes(subPermissionKey)
                        ? 'REQUIRED'
                        : 'OPTIONAL', onChange: function (values) {
                        onSettingChange(__assign(__assign({}, subPermissionOption), { values: values }));
                    }, accessGroupDetail: accessGroupDetail }));
            }
        }
        if ('clockIn' in subPermissionOption) {
            return (_jsx(MobileTrackingDropdown, { defaultOptions: subPermissionOption, onChange: function (options) {
                    onSettingChange(__assign(__assign({}, subPermissionOption), options));
                }, showAlways: subPermissionKey === 'locationTracking' }));
        }
        return null;
    }, [accessGroupDetail, isCustomizeShiftCardMobileOn]);
    var useUpdateAccessGroupMutation = useSetting().useUpdateAccessGroupMutation;
    var updateAccessGroup = useUpdateAccessGroupMutation().mutateAsync;
    var onSettingChange = useCallback(function (newMobileSetting) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateAccessGroup({
                        accessGroupId: accessGroupDetail.id,
                        mobilePermissions: newMobileSetting,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [accessGroupDetail, updateAccessGroup]);
    var recommendShiftsFeatureEnabled = useRecoilValue(featureEnableStatusState(SHIFT_RECOMMENDATIONS_FEATURE));
    return (_jsxs("div", { className: "AccessTabContainer", children: [_jsx(AccessDetailHelperBanner, { title: "Mobile Settings", description: "You've granted mobile access to this user. Now you can define their mobile experience" }), MobilePermissionDetails.map(function (featurePermission) {
                var _a;
                return (_jsxs("div", { className: "pb-6", children: [_jsx(AccessDetailGroupItem, { leadingIcon: featurePermission.icon, title: featurePermission.title, subtitle: featurePermission.description, onToggle: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                var tempMobileSettings, newMobileSetting;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            tempMobileSettings = cloneDeep(mobileSettings);
                                            newMobileSetting = __assign(__assign({}, tempMobileSettings), (_a = {}, _a[featurePermission.id] = __assign(__assign({}, tempMobileSettings[featurePermission.id]), { enabled: e.target.checked }), _a));
                                            setMobileSettings(newMobileSetting);
                                            return [4 /*yield*/, onSettingChange(newMobileSetting)];
                                        case 1:
                                            _b.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, defaultChecked: mobileSettings[featurePermission.id].enabled }), (_a = featurePermission.children) === null || _a === void 0 ? void 0 : _a.filter(function (child) {
                            var _a;
                            if (child.id === 'hideOthersShiftSchemas') {
                                return (_a = mobileSettings.calendar.subPermissions.viewOthersShifts) === null || _a === void 0 ? void 0 : _a.enabled;
                            }
                            return true;
                        }).map(function (child) {
                            var subPermissionOption = mobileSettings[featurePermission.id].subPermissions[child.id];
                            if (!subPermissionOption ||
                                (child.id === 'requireShiftRecommendationAdminApproval' &&
                                    !recommendShiftsFeatureEnabled))
                                return null;
                            var hasCheckOption = 'enabled' in subPermissionOption &&
                                subPermissionOption.enabled !== undefined;
                            return (_jsx(AccessDetailGroupChild, { title: child.title, subTitle: child.description, onCheck: hasCheckOption
                                    ? function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                        var tempMobileSettings, newMobileSetting;
                                        var _a, _b;
                                        return __generator(this, function (_c) {
                                            switch (_c.label) {
                                                case 0:
                                                    tempMobileSettings = cloneDeep(mobileSettings);
                                                    newMobileSetting = __assign(__assign({}, tempMobileSettings), (_a = {}, _a[featurePermission.id] = __assign(__assign({}, tempMobileSettings[featurePermission.id]), { subPermissions: __assign(__assign({}, tempMobileSettings[featurePermission.id].subPermissions), (_b = {}, _b[child.id] = __assign(__assign({}, tempMobileSettings[featurePermission.id].subPermissions[child.id]), { enabled: e.target.checked }), _b)) }), _a));
                                                    setMobileSettings(newMobileSetting);
                                                    return [4 /*yield*/, onSettingChange(newMobileSetting)];
                                                case 1:
                                                    _c.sent();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); }
                                    : undefined, checked: hasCheckOption && (subPermissionOption === null || subPermissionOption === void 0 ? void 0 : subPermissionOption.enabled), tailChild: renderSubPermissionTailChild(subPermissionOption, child.id, function (newSetting) { return __awaiter(void 0, void 0, void 0, function () {
                                    var tempMobileSettings, newMobileSetting;
                                    var _a, _b;
                                    return __generator(this, function (_c) {
                                        switch (_c.label) {
                                            case 0:
                                                tempMobileSettings = cloneDeep(mobileSettings);
                                                newMobileSetting = __assign(__assign({}, tempMobileSettings), (_a = {}, _a[featurePermission.id] = __assign(__assign({}, tempMobileSettings[featurePermission.id]), { subPermissions: __assign(__assign({}, tempMobileSettings[featurePermission.id]
                                                        .subPermissions), (_b = {}, _b[child.id] = __assign(__assign(__assign({}, tempMobileSettings[featurePermission.id].subPermissions[child.id]), newSetting), { enabled: hasCheckOption
                                                            ? subPermissionOption === null || subPermissionOption === void 0 ? void 0 : subPermissionOption.enabled
                                                            : undefined }), _b)) }), _a));
                                                setMobileSettings(newMobileSetting);
                                                return [4 /*yield*/, onSettingChange(newMobileSetting)];
                                            case 1:
                                                _c.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }, mobileSettings[featurePermission.id].enabled), isParentDisabled: !mobileSettings[featurePermission.id].enabled }, child.id));
                        })] }, featurePermission.id));
            })] }));
};
export default React.memo(MobileSettings);
