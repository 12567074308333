var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Skeleton, Spin } from 'antd';
import { produce } from 'immer';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useScheduleApi } from 'src/hooks/api';
import { useTimeZone, useWindowSize } from 'src/hooks/component';
import ZiraWarningPopconfirm from 'src/ui/ZiraWarningPopconfirm';
import { ClaimUserAvailability, } from '../../../../services/Interfaces';
import { noteColumn, requestStatusTag, renderUserCell, shiftSimpleTag, renderOtherVisibleShiftColumns, renderOriginalAssigneeCell, } from '../Utils';
var ShiftClaimTableRow = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var blockId = _a.blockId, recordData = _a.recordData, index = _a.index, style = _a.style, tableData = _a.tableData, statusFilter = _a.statusFilter, schemaOptions = _a.schemaOptions, claimerMetadata = _a.claimerMetadata, requestType = _a.requestType, timeZone = _a.timeZone, setRowHeight = _a.setRowHeight, setTableData = _a.setTableData, setConflictModalData = _a.setConflictModalData, onOpenRecordDetailModal = _a.onOpenRecordDetailModal;
    var record = recordData[index];
    var isSameShift = index > 0 && ((_c = (_b = recordData[index - 1]) === null || _b === void 0 ? void 0 : _b[requestType]) === null || _c === void 0 ? void 0 : _c.recordId) == ((_d = record[requestType]) === null || _d === void 0 ? void 0 : _d.recordId);
    var nextIsSameShift = index < recordData.length - 1 &&
        ((_f = (_e = recordData[index + 1]) === null || _e === void 0 ? void 0 : _e[requestType]) === null || _f === void 0 ? void 0 : _f.recordId) == ((_g = record === null || record === void 0 ? void 0 : record[requestType]) === null || _g === void 0 ? void 0 : _g.recordId);
    var rowRef = useRef(null);
    var windowWidth = useWindowSize().windowWidth;
    useEffect(function () {
        if (rowRef.current) {
            setRowHeight(index, rowRef.current.clientHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth]);
    var _h = useScheduleApi(), useShiftRequestAction = _h.useShiftRequestAction, useCheckUserConflictsOnShiftRequest = _h.useCheckUserConflictsOnShiftRequest;
    var _j = useTimeZone(timeZone), momentFormatFn = _j.momentFormatFn, timezoneAbbr = _j.timezoneAbbr;
    var shiftCell = useCallback(function (shift) {
        var name = shift.name, startAt = shift.startAt, endAt = shift.endAt, locations = shift.locations, roles = shift.roles, shiftCount = shift.shiftCount;
        var startAtDate = moment(startAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        var endAtDate = moment(endAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        var startAtTime = momentFormatFn(startAtDate, 'h:mma').slice(0, -1);
        var endAtTime = momentFormatFn(endAtDate, 'h:mma').slice(0, -1);
        var startAtDateString = momentFormatFn(startAtDate, 'MMM D');
        var endAtDateString = startAtDate.day() !== endAtDate.day()
            ? momentFormatFn(endAtDate, 'MMM D') + ', '
            : '';
        var timezone = timezoneAbbr();
        var className = requestType === 'shift' ? 'ShiftCell' : 'ShiftCell ShiftGroupCell';
        return (_jsxs("div", { className: className, onClick: function () { return onOpenRecordDetailModal(shift.recordId); }, children: [name && _jsx("span", { className: "ShiftDate font-semibold", children: name }), _jsxs("div", { className: "ShiftDateTimeWrapper", children: [_jsx("span", { className: "ShiftDate", children: startAtDateString + ', ' }), _jsx("span", { className: "ShiftTime", children: "".concat(startAtTime, " - ").concat(endAtDateString).concat(endAtTime) +
                                (timezone ? ", ".concat(timezone) : '') })] }), locations.length > 0 &&
                    locations.map(function (location) { return shiftSimpleTag(location.label, location.color); }), roles.length > 0 &&
                    roles.map(function (role) { return shiftSimpleTag(role.label, role.color); }), shift.openCount > 0 && statusFilter === 'pending' && (_jsx("div", { className: "ShiftTagCell", children: shift.openCount })), shiftCount && _jsxs("span", { className: "ShiftDate", children: ["Total Shifts: ", shiftCount] }), renderOtherVisibleShiftColumns(shift.recordId, schemaOptions, tableData), requestType === 'shiftGroup' && (_jsx("div", { className: "".concat(className, " !absolute h-full w-full top-[-20px] right-[-20px] z-[-1]") }))] }));
    }, [
        momentFormatFn,
        onOpenRecordDetailModal,
        requestType,
        schemaOptions,
        statusFilter,
        tableData,
        timezoneAbbr,
    ]);
    var _k = useState(), isProcessingAction = _k[0], setIsProcessingAction = _k[1];
    var processKey = useCallback(function (record) { return record[requestType].recordId + record.user.recordId; }, [requestType]);
    var onActionRequest = useCallback(function (isApprove, record) { return __awaiter(void 0, void 0, void 0, function () {
        var requestShiftId, requestUserId;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    requestShiftId = record[requestType].recordId;
                    requestUserId = record.user.recordId;
                    setConflictModalData(undefined);
                    setIsProcessingAction((_a = {},
                        _a[processKey(record)] = true,
                        _a));
                    return [4 /*yield*/, useShiftRequestAction.mutateAsync({
                            isApproved: isApprove,
                            shiftRecordId: requestType === 'shift' ? requestShiftId : undefined,
                            shiftGroupRecordId: requestType === 'shiftGroup' ? requestShiftId : undefined,
                            claimUserRecordId: requestUserId,
                            blockId: blockId,
                        }, {
                            onSuccess: function () {
                                var _a;
                                //!MARK: - update table data locally only
                                setIsProcessingAction((_a = {},
                                    _a[processKey(record)] = false,
                                    _a));
                                //!MARK: - table data update will trigger record data update
                                var nextTableDataState = produce(tableData, function (draft) {
                                    draft === null || draft === void 0 ? void 0 : draft.requests.map(function (request) {
                                        if (isApprove) {
                                            if (request.recordId === requestShiftId) {
                                                // Reduce open count locally when approved
                                                request.openCount--;
                                                request.claimUsers.map(function (user) {
                                                    // Update user status to approved
                                                    if (user.recordId === requestUserId) {
                                                        user.status = 'approved';
                                                    }
                                                    else {
                                                        // For release->recommendation shift request, when one got approved, the rest should be canceled
                                                        // For open shift request, when open count is 0, the rest should be canceled
                                                        if ((record.shift.originalUser ||
                                                            request.openCount === 0) &&
                                                            user.status === 'pending') {
                                                            // Update other users status to canceled, so other requests cannot be claimed
                                                            user.status = 'canceled';
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                        else {
                                            if (request.recordId === requestShiftId) {
                                                request.claimUsers.map(function (user) {
                                                    if (user.recordId === requestUserId) {
                                                        user.status = 'rejected';
                                                    }
                                                });
                                            }
                                        }
                                    });
                                });
                                setTableData(nextTableDataState);
                            },
                            onError: function () {
                                var _a;
                                setIsProcessingAction((_a = {},
                                    _a[processKey(record)] = false,
                                    _a));
                                toast('Cannot approve this request.');
                            },
                        })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockId, processKey, setConflictModalData, setTableData, requestType, tableData]);
    var onApproveActionPrecheck = useCallback(function (record) { return __awaiter(void 0, void 0, void 0, function () {
        var requestShiftId, requestUserId;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsProcessingAction((_a = {},
                        _a[processKey(record)] = true,
                        _a));
                    requestShiftId = record[requestType].recordId;
                    requestUserId = record.user.recordId;
                    return [4 /*yield*/, useCheckUserConflictsOnShiftRequest.mutateAsync({
                            userRid: requestUserId,
                            shiftRid: requestShiftId,
                        }, {
                            onSuccess: function (resp) {
                                var _a;
                                if (resp === null || resp === void 0 ? void 0 : resp.conflictShifts.length) {
                                    // show modal when trying to approve a user with conflicting shift
                                    setConflictModalData({
                                        record: record,
                                        conflicts: resp.conflictShifts,
                                    });
                                    setIsProcessingAction((_a = {},
                                        _a[processKey(record)] = false,
                                        _a));
                                    return;
                                }
                                if (!(resp === null || resp === void 0 ? void 0 : resp.conflictShifts.length)) {
                                    onActionRequest(true, record);
                                    return;
                                }
                                toast('Something went wrong. Please try again later.', {
                                    type: 'error',
                                    position: 'bottom-center',
                                });
                            },
                            onError: function () {
                                toast('Something went wrong. Please try again later.');
                            },
                        })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onActionRequest, processKey, setConflictModalData]);
    var onPerformAction = useCallback(function (isApprove, record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(isApprove && record.shift)) return [3 /*break*/, 2];
                    return [4 /*yield*/, onApproveActionPrecheck(record)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2: return [4 /*yield*/, onActionRequest(isApprove, record)];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [onActionRequest, onApproveActionPrecheck]);
    var actionCell = useCallback(function (user, record) {
        var _a;
        var status = user.status;
        switch (status) {
            case 'pending':
                return (_jsx("div", { className: "ActionCellButtons", children: _jsxs(Spin, { indicator: _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true }), spinning: (_a = isProcessingAction === null || isProcessingAction === void 0 ? void 0 : isProcessingAction[processKey(record)]) !== null && _a !== void 0 ? _a : false, children: [requestType === 'shift' ? (_jsxs("div", { className: "ActionButton ApproveButton", onClick: function () { return onPerformAction(true, record); }, children: [_jsx(CheckOutlined, { style: { color: '#FFF' } }), "Approve"] })) : (_jsx(ZiraWarningPopconfirm, { title: _jsxs("div", { children: [_jsx("div", { children: "Are you sure you want to approve this shift group request?" }), _jsx("div", { children: "This will assign all open shifts in the group to the user." })] }), onConfirm: function () { return onPerformAction(true, record); }, placement: "topRight", okButtonProps: {
                                    className: '!text-white !bg-[#13b35d]',
                                }, okText: "Approve", children: _jsxs("div", { className: "ActionButton ApproveButton", children: [_jsx(CheckOutlined, { style: { color: '#FFF' } }), "Approve"] }) })), _jsxs("div", { className: "ActionButton RejectButton", onClick: function () { return onPerformAction(false, record); }, children: [_jsx(CloseOutlined, { className: "CloseIcon" }), "Reject"] })] }) }));
            default:
                return requestStatusTag(status);
        }
    }, [isProcessingAction, onPerformAction, processKey, requestType]);
    var availabilityCell = useCallback(function (user, shiftRId) {
        var _a;
        var metaData = (_a = claimerMetadata[shiftRId]) === null || _a === void 0 ? void 0 : _a[user.recordId];
        if (!metaData) {
            return (_jsx("div", { className: "AvailabilityCell", children: _jsx(Skeleton.Input, { active: true }) }));
        }
        var hasConflictingShift = metaData.claimerAvailability === ClaimUserAvailability.CONFLICTING &&
            user.status !== 'approved';
        var userScheduledHrs = parseFloat(moment.duration(metaData.scheduledDuration).asHours().toFixed(2));
        return (_jsxs("div", { className: "AvailabilityCell", children: [_jsxs("div", { className: "UserScheduledHrs", style: { color: userScheduledHrs > 40 ? '#B3342F' : 'black' }, children: [userScheduledHrs, " hr", userScheduledHrs != 1 ? 's' : '', hasConflictingShift ? shiftSimpleTag('Conflicting Shift', '#FFF9ED') : ''] }), _jsx("span", { className: "UserRequestTime", children: "Scheduled that week" })] }));
    }, [claimerMetadata]);
    return (_jsxs("div", { ref: rowRef, className: "ShiftRequestRow", style: __assign(__assign({}, style), { marginBottom: nextIsSameShift || index == recordData.length - 1 ? 'none' : '6px', height: 'auto' }), children: [!isSameShift ? shiftCell(record[requestType]) : _jsx("div", { className: "EmptyCell" }), _jsxs("span", { className: "ShiftRequestRowBorder", children: [renderUserCell(record.user.recordId, momentFormatFn(moment(record.user.requestTime), 'MMM D, h:mma'), record.user.name, function () { return onOpenRecordDetailModal(record[requestType].recordId); }, record.user.avatarUrl), requestType === 'shift' && 'pending' && record.user.status !== 'approved' && (_jsx(_Fragment, { children: availabilityCell(record.user, record[requestType].recordId) })), renderOriginalAssigneeCell(function () { var _a, _b; return onOpenRecordDetailModal((_b = (_a = record[requestType].originalUser) === null || _a === void 0 ? void 0 : _a.recordId) !== null && _b !== void 0 ? _b : ''); }, record[requestType].originalUser), noteColumn(record.user.notes), actionCell(record.user, record)] })] }));
};
export default React.memo(ShiftClaimTableRow);
