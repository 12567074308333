import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Layout } from 'antd';
import tinycolor from 'tinycolor2';
import SiderHeader from '../CreateAccountDetailModal/SiderHeader';
import AccountTemplateCard from './AccountTemplateCard';
import CategoryIconWrapper from './CategoryIconWrapper';
var Sider = Layout.Sider, Content = Layout.Content;
var AccountTemplateApplySelectionPage = function (props) {
    var templateCards = props.templateCards, categoryMetaData = props.categoryMetaData, onCreateAccountAndApplyTemplate = props.onCreateAccountAndApplyTemplate, goPrevStep = props.goPrevStep;
    var quoteBackgroundColor = tinycolor(categoryMetaData.color).lighten(4).toString();
    return (_jsxs(Layout, { className: "h-full", children: [_jsxs(Sider, { width: "30vw", className: "AccountTemplateSider overflow-y-auto", style: { backgroundColor: categoryMetaData.color || 'white' }, children: [_jsx(SiderHeader, {}), _jsxs("div", { className: "SiderContent", children: [_jsxs("div", { className: "CategoryInfoContainer", children: [_jsx("div", { className: "CategoryImg", children: _jsx(CategoryIconWrapper, { color: "white", path: categoryMetaData.icon }) }), _jsx("div", { className: "mt-6 text-3xl font-bold text-white", children: categoryMetaData.displayName }), _jsx("div", { className: "CategoryDescription text-white", children: categoryMetaData.description })] }), _jsxs("div", { className: "CategoryQuoteInfo", children: [_jsx("div", { className: "QuoterImg", children: _jsx("img", { src: categoryMetaData.quoteIcon }) }), _jsxs("div", { className: "QuoteDescription text-white", style: { backgroundColor: quoteBackgroundColor }, children: [_jsx("div", { className: "QuoteText", children: categoryMetaData.quoteText }), _jsx("div", { className: "QuoterInfo", children: categoryMetaData.quoter })] })] })] })] }), _jsxs(Layout, { className: "bg-gray-50", children: [_jsx(Button, { onClick: goPrevStep, className: "GoBackButton", children: "\u2190" }), _jsx(Content, { children: _jsxs("div", { className: "AccountTemplateContent", children: [_jsx("div", { className: "Title", children: "Select Your Business Type" }), _jsx("div", { className: "AccountTemplateCardContainer", children: templateCards === null || templateCards === void 0 ? void 0 : templateCards.map(function (template, index) {
                                        return (_jsx(AccountTemplateCard, { template: template, applyTemplate: function () {
                                                return onCreateAccountAndApplyTemplate(template.identifier);
                                            } }, index));
                                    }) })] }) })] })] }));
};
export default React.memo(AccountTemplateApplySelectionPage);
