import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { CollectionType } from 'src/shared';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { useAccountApi } from 'src/hooks/api';
import { getFieldDisplay } from '../util';
var CustomFieldMobilePreview = function (_a) {
    var _b;
    var customFieldValues = _a.customFieldValues, templateId = _a.templateId;
    var useGetSchemaColumnsInCollection = useAccountApi().useGetSchemaColumnsInCollection;
    var taskCollectionId = (_b = useRecoilValue(standardCollectionIdSelector(CollectionType.TASK))) !== null && _b !== void 0 ? _b : '';
    var schemas = useGetSchemaColumnsInCollection({
        collectionId: taskCollectionId !== null && taskCollectionId !== void 0 ? taskCollectionId : '',
        options: {
            enabled: !!taskCollectionId,
        },
    }).data;
    var customFieldSchemas = useMemo(function () {
        var _a;
        return (_a = schemas === null || schemas === void 0 ? void 0 : schemas.columns.filter(function (schema) { return !schema.nativePath; })) !== null && _a !== void 0 ? _a : [];
    }, [schemas]);
    return (_jsx("div", { className: "ml-5 mr-5 mb-[5px]", children: customFieldValues.map(function (value, index) {
            {
                var fieldSchema = customFieldSchemas === null || customFieldSchemas === void 0 ? void 0 : customFieldSchemas.find(function (schema) {
                    return schema.id === value.schemaId;
                });
                if (!fieldSchema)
                    return null;
                return (_jsxs("div", { className: "mt-3", children: [_jsx("div", { className: "text-gray-400 font-semibold mb-1", children: fieldSchema.name }), getFieldDisplay({
                            schemaInfo: fieldSchema,
                            taskCollectionId: taskCollectionId,
                            templateId: templateId,
                            hideInputBorder: true,
                        })] }, "custom-field-value-".concat(value.recordId, "-").concat(index)));
            }
        }) }));
};
export default React.memo(CustomFieldMobilePreview);
