import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, memo, useEffect, cloneElement, Children, useRef } from 'react';
import { Button, Input, Modal } from 'antd';
import { capitalize, upperFirst } from 'lodash';
import { TrashOutlinedIcon } from 'src/utils/icons/DropdownIcons/TrashOutlined';
import './HardConfirmWrapper.scss';
/**
 * HardConfirmWrapper is used similarly to Popconfirm - it wraps the trigger component that
 * needs the hard confirmation.
 *
 * Example:
 *
 * <HardConfirmWrapper changeType="delete" entityName="user" onConfirm={onConfirmDelete}>
 *     <Button>Delete User</Button>
 * </HardConfirmWrapper>
 */
var HardConfirmWrapper = function (props) {
    var children = props.children, changeType = props.changeType, entityName = props.entityName, explanationText = props.explanationText, onConfirm = props.onConfirm, _a = props.zIndex, zIndex = _a === void 0 ? 1200 : _a, _b = props.hasPluralEntityName, hasPluralEntityName = _b === void 0 ? false : _b;
    var inputRef = useRef(null);
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var _d = useState(''), confirmationText = _d[0], setConfirmationText = _d[1];
    var isConfirmationBlocked = confirmationText.toLowerCase() !== changeType.toLowerCase();
    useEffect(function () {
        setConfirmationText('');
    }, [children]);
    useEffect(function () {
        if (isOpen) {
            // when used inside another modal, adding this trick to correctly set the focus on input element
            setTimeout(function () {
                var _a;
                (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }, 0);
        }
    }, [isOpen]);
    var childElement = cloneElement(Children.only(children), { onClick: function () { return setIsOpen(true); } });
    return (_jsxs(_Fragment, { children: [childElement, isOpen && (_jsx(Modal, { zIndex: zIndex, className: "HardConfirmWrapper min-w-[600px]", open: isOpen, title: _jsxs("div", { className: "flex items-center gap-2", children: [_jsx(TrashOutlinedIcon, { color: "#37383E" }), _jsxs("span", { className: "text-[#EF241C] text-[16px]", children: [capitalize(changeType), ' ', entityName
                                    .split(' ')
                                    .map(function (word) { return upperFirst(word); })
                                    .join(' ')] })] }), centered: true, footer: null, onCancel: function () { return setIsOpen(false); }, children: _jsxs("div", { children: [_jsx("div", { className: "bg-[#F7CBC5] text-red-1000 text-sm font-medium py-5 px-6", children: "Please read this to avoid permanent data loss and potential workflow errors." }), _jsxs("div", { className: "flex flex-col gap-4 py-4 px-6 text-sm leading-6", children: [_jsxs("span", { className: "font-medium", children: ["Are you sure you want to ", changeType, ' ', hasPluralEntityName ? 'these' : 'this', " ", entityName, "?", explanationText ? " ".concat(explanationText, " ") : ' ', "This action", ' ', _jsx("span", { className: "font-bold", children: "CANNOT" }), " be undone."] }), _jsxs("span", { className: "font-semibold", children: ["Please type ", changeType.toUpperCase(), " in the box below to confirm this action."] })] }), _jsxs("form", { className: "px-6 pb-5 flex flex-col gap-4", onSubmit: function (e) {
                                e.preventDefault();
                                if (isConfirmationBlocked)
                                    return;
                                onConfirm();
                                setIsOpen(false);
                            }, children: [_jsx(Input, { ref: inputRef, className: "border-0 py-3 bg-[#F2F8FA] text-[32px] tracking-widest text-center uppercase", placeholder: changeType, value: confirmationText, onChange: function (e) { return setConfirmationText(e.target.value); } }), _jsxs(Button, { danger: true, htmlType: "submit", className: "w-full bg-[#EF241C] text-sm text-white font-medium hover:bg-[#EF241C] hover:text-white", disabled: isConfirmationBlocked, children: ["I understand, ", changeType, " ", hasPluralEntityName ? 'these' : 'this', ' ', entityName] })] })] }) }))] }));
};
export default memo(HardConfirmWrapper);
