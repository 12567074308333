import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { participantsInNewThread } from 'src/state/atoms/chatState';
import { isCreatingThreadSelector } from 'src/state/selectors/withChatState';
import { CreateThreadBackgroundIcon } from 'src/utils/icons/ChatIcons/CreateThreadBackgroundIcon';
import { ModalChatCreateThreadIcon } from 'src/utils/icons/ChatIcons/ModalChatCreateThreadIcon';
import { MessageSenderType, ThreadType } from '../../interface';
import BroadcastBanner from '../../BroadcastBanner';
import { CreateThreadButton } from '../../CreateThreadButton';
import NewThreadFooter from './NewThreadFooter';
import '../ChatContent.scss';
var NewThreadScreen = function (_a) {
    var isInRecordModal = _a.isInRecordModal;
    var participantsInThread = useRecoilValue(participantsInNewThread);
    var threadType = useRecoilValue(isCreatingThreadSelector);
    var messageTypeText = threadType === ThreadType.BROADCAST ? 'broadcast' : 'message';
    var isBroadcast = useMemo(function () {
        var _a;
        return (_a = participantsInThread === null || participantsInThread === void 0 ? void 0 : participantsInThread.participants) === null || _a === void 0 ? void 0 : _a.some(function (participant) { return participant.type !== MessageSenderType.USER; });
    }, [participantsInThread]);
    if (isInRecordModal && threadType) {
        return (_jsxs("div", { className: "MessageScreen NewThread", children: [_jsxs("div", { className: "ScrollChatNoMain", children: [_jsx(ModalChatCreateThreadIcon, { className: "flex justify-center mb-3" }), _jsxs(_Fragment, { children: [_jsxs("span", { className: "NoMessage", children: ["Send a ", messageTypeText] }), _jsxs("span", { className: "NoMessageSub", children: ["Your ", messageTypeText, " will automatically get routed to their app or as a SMS"] })] })] }), _jsx(NewThreadFooter, {})] }));
    }
    return (_jsxs("div", { className: "MessageScreen NewThread", children: [isBroadcast && (_jsx(BroadcastBanner, { broadcastRecipients: participantsInThread === null || participantsInThread === void 0 ? void 0 : participantsInThread.participants })), _jsxs("div", { className: "ScrollChatNoMain", children: [_jsx(CreateThreadBackgroundIcon, { className: "flex justify-center mb-3" }), threadType ? (_jsxs(_Fragment, { children: [_jsxs("span", { className: "NoMessage", children: ["Send a ", messageTypeText] }), _jsxs("span", { className: "NoMessageSub", children: ["Your ", messageTypeText, " will automatically get routed to their app or as a SMS"] })] })) : (_jsxs("span", { className: "NoMessage", children: ["Click the\u00A0", _jsx(CreateThreadButton, {}), "\u00A0to get started"] }))] }), threadType && _jsx(NewThreadFooter, {})] }));
};
export default NewThreadScreen;
