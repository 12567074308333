import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputNumber, Select } from 'antd';
import { useCallback, useState } from 'react';
var Option = Select.Option;
var MobileTimeInputDropdown = function (_a) {
    var defaultTime = _a.defaultTime, onChange = _a.onChange, showPreSelection = _a.showPreSelection, disableByDefault = _a.disableByDefault;
    var _b = useState(showPreSelection ? defaultTime : Math.max(defaultTime, 0)), time = _b[0], setTime = _b[1];
    var handleSetTime = useCallback(function (time) {
        setTime(time);
        onChange(time);
    }, [onChange]);
    var selectBefore = (_jsxs(Select, { value: time > 0, onChange: function (value) {
            handleSetTime(value ? 10 : -1);
        }, style: { width: '200px', fontWeight: 600 }, children: [_jsx(Option, { value: false, children: "No Limit" }), _jsx(Option, { value: true, children: "Before shifts start" })] }));
    return (_jsx(InputNumber, { addonBefore: showPreSelection ? selectBefore : null, addonAfter: "mins", value: time, formatter: function (value) {
            if (value && value > 0) {
                return "".concat(value);
            }
            return '-';
        }, onChange: function (e) {
            if (e) {
                setTime(e);
            }
        }, onBlur: function () { return handleSetTime(time); }, disabled: (disableByDefault || false) && time < 0, style: { width: showPreSelection ? '300px' : '100px' }, className: "bg-gray-50 font-medium" }));
};
export default MobileTimeInputDropdown;
