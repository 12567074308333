var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, Divider, Modal, Tabs } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useSetting } from 'src/hooks/api';
import SettingsState from 'src/hooks/state/Settings';
var MobileProfileLinkedOptionButton = function (_a) {
    var onChange = _a.onChange;
    var accessGroupDetail = SettingsState.useContainer().accessGroupDetail;
    var useGetMobileProfileLinkedData = useSetting().useGetMobileProfileLinkedData;
    var data = useGetMobileProfileLinkedData(accessGroupDetail.id).data;
    var _b = useState(data), linkedDataOptions = _b[0], setLinkedDataOptions = _b[1];
    var _c = useState(false), isModalVisible = _c[0], setIsModalVisible = _c[1];
    useEffect(function () {
        setLinkedDataOptions(data);
    }, [data]);
    if (!linkedDataOptions)
        return _jsx("div", { children: "Loading..." });
    var visibleData = linkedDataOptions.filter(function (o) { return o.enabled; });
    return (_jsxs(_Fragment, { children: [_jsxs("div", { role: "button", className: "rounded bg-gray-50 px-3 py-1 font-medium hover:cursor-pointer", onClick: function () { return setIsModalVisible(true); }, children: [visibleData.length, " ", visibleData.length <= 1 ? 'Data Source' : 'Data Sources', ' ', "Visible"] }), _jsx(MobileProfileLinkedDataSettingModal, { isModalVisible: isModalVisible, setIsModalVisible: setIsModalVisible, linkedDataOptions: linkedDataOptions, onSave: function (options) {
                    onChange(options);
                    setLinkedDataOptions(options);
                } })] }));
};
var MobileProfileLinkedDataSettingModal = function (_a) {
    var isModalVisible = _a.isModalVisible, setIsModalVisible = _a.setIsModalVisible, defaultLinkedDataOptions = _a.linkedDataOptions, onSave = _a.onSave;
    var _b = useState(defaultLinkedDataOptions), linkedDataOptions = _b[0], setLinkedDataOptions = _b[1];
    useEffect(function () {
        setLinkedDataOptions(defaultLinkedDataOptions);
    }, [defaultLinkedDataOptions]);
    var visibleConnections = linkedDataOptions.filter(function (o) { return o.enabled; });
    var onToggleOptions = useCallback(function (dataSource, enabled) {
        var newOptions = linkedDataOptions.map(function (o) {
            if (o.schemaId === dataSource.schemaId &&
                o.standardRelationType === dataSource.standardRelationType) {
                return __assign(__assign({}, o), { enabled: enabled });
            }
            return o;
        });
        setLinkedDataOptions(newOptions);
    }, [linkedDataOptions, setLinkedDataOptions]);
    var connectionEntry = useCallback(function (dataSource) {
        return (_jsxs("div", { children: [_jsxs("div", { className: "flex", children: [_jsx("div", { className: "grow text-base font-medium", children: dataSource.title }), _jsx(Checkbox, { checked: dataSource.enabled, onChange: function (e) { return onToggleOptions(dataSource, e.target.checked); } })] }), _jsx(Divider, { className: "py-1" })] }, "".concat(dataSource.schemaId, "-").concat(dataSource.standardRelationType, "-").concat(dataSource.title)));
    }, [onToggleOptions]);
    var listClass = 'px-3 py-4 mt-2';
    return (_jsx(Modal, { className: "PermissionModal", open: isModalVisible, onCancel: function () {
            setIsModalVisible(false);
            onSave(linkedDataOptions);
        }, footer: null, style: { height: '30vh', width: '540px' }, destroyOnClose: true, children: _jsxs("div", { className: "SchemaPermissionOverviewContent", children: [_jsxs("div", { className: "px-8 pb-1 pt-6", children: [_jsx("div", { className: "text-lg font-semibold", children: "Data Sources" }), _jsx("span", { className: "font-medium text-gray-500", children: "Select which data connections are visible on this user group's profile page." })] }), _jsx(Divider, {}), _jsx(Tabs, { defaultActiveKey: "visibleSchemas", items: [
                        {
                            label: "Visible Connections (".concat(visibleConnections.length, ")"),
                            key: 'visibleConnections',
                            children: (_jsx("div", { className: listClass, children: visibleConnections.map(connectionEntry) })),
                        },
                        {
                            label: "All Connections (".concat(linkedDataOptions.length, ")"),
                            key: 'allSchemas',
                            children: (_jsx("div", { className: listClass, children: linkedDataOptions.map(connectionEntry) })),
                        },
                    ] })] }) }));
};
export default MobileProfileLinkedOptionButton;
