var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, Button } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState, useRecoilCallback, useRecoilValue } from 'recoil';
import { useAccountApi } from 'src/hooks/api';
import { useRecordComponent } from 'src/hooks/component';
import { CollectionTableType, CollectionType } from 'src/shared';
import { collectionTableState, collectionTableSchemaMutationsState, collectionTableRecordState, } from 'src/state';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { NEW_TASK_TEMPLATE_ID } from '../../constant';
import TaskTemplateCustomFieldRow from './TaskTemplateCustomFieldRow';
var TaskTemplateCustomFields = function (_a) {
    var _b;
    var selectedTaskId = _a.selectedTaskId, customFieldInfoAndView = _a.customFieldInfoAndView, setCustomFields = _a.setCustomFields;
    var customFields = useMemo(function () { var _a; return (_a = customFieldInfoAndView === null || customFieldInfoAndView === void 0 ? void 0 : customFieldInfoAndView.customFieldInfo.customFieldSchemas) !== null && _a !== void 0 ? _a : []; }, [customFieldInfoAndView]);
    var customFieldCells = useMemo(function () { return customFieldInfoAndView === null || customFieldInfoAndView === void 0 ? void 0 : customFieldInfoAndView.customFieldView; }, [customFieldInfoAndView === null || customFieldInfoAndView === void 0 ? void 0 : customFieldInfoAndView.customFieldView]);
    var useGetSchemaColumnsInCollection = useAccountApi().useGetSchemaColumnsInCollection;
    var editRecordCell = useRecordComponent().editRecordCell;
    var taskCollectionId = (_b = useRecoilValue(standardCollectionIdSelector(CollectionType.TASK))) !== null && _b !== void 0 ? _b : '';
    var dataSource = useMemo(function () { return ({
        type: CollectionTableType.TASK_TEMPLATE_MODAL,
        collectionId: taskCollectionId !== null && taskCollectionId !== void 0 ? taskCollectionId : '',
    }); }, [taskCollectionId]);
    var isCreatingNew = selectedTaskId === NEW_TASK_TEMPLATE_ID;
    var setCollectionTableData = useSetRecoilState(collectionTableState(dataSource));
    //! Custom fields state management
    var setSchemasToMutate = useSetRecoilState(collectionTableSchemaMutationsState(dataSource));
    var recordData = useRecoilValue(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: selectedTaskId })));
    var _c = useGetSchemaColumnsInCollection({
        collectionId: taskCollectionId !== null && taskCollectionId !== void 0 ? taskCollectionId : '',
        options: {
            enabled: !!taskCollectionId,
        },
    }), schemas = _c.data, schemasFetched = _c.isFetched;
    var _d = useState(false), isInitialized = _d[0], setIsInitialized = _d[1];
    useEffect(function () {
        setIsInitialized(false);
    }, [selectedTaskId]);
    //! Initialize records & schema mutations state
    useEffect(function () {
        if (isInitialized || !taskCollectionId || !(schemas === null || schemas === void 0 ? void 0 : schemas.columns)) {
            return;
        }
        var initializeState = function () {
            var _a, _b;
            var _c;
            var cellData = {};
            if (isCreatingNew) {
                var taskModalRecord = recordData !== null && recordData !== void 0 ? recordData : {};
                cellData = (_a = {}, _a[selectedTaskId] = taskModalRecord, _a);
            }
            else {
                var templateModalRecord = customFieldCells !== null && customFieldCells !== void 0 ? customFieldCells : {};
                cellData = (_b = {},
                    _b[selectedTaskId] = templateModalRecord,
                    _b);
            }
            setCollectionTableData({
                collectionInfo: {
                    id: taskCollectionId,
                    type: CollectionType.TASK,
                    name: 'Tasks',
                },
                columns: schemas.columns,
                rows: [],
                cells: cellData,
                nonSchemaNativeFieldsMap: {},
                permissions: { canEditCollection: false, canEditSchemas: false },
            });
            setSchemasToMutate((_c = customFields.map(function (field) { return field.value; })) !== null && _c !== void 0 ? _c : []);
            setIsInitialized(true);
        };
        initializeState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskCollectionId, schemas, selectedTaskId, customFieldCells]);
    useEffect(function () {
        return function () {
            setCollectionTableData(undefined);
            setSchemasToMutate([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var availableSchemas = useMemo(function () {
        var _a;
        return (_a = schemas === null || schemas === void 0 ? void 0 : schemas.columns.filter(function (schema) { return !schema.nativePath; })) !== null && _a !== void 0 ? _a : [];
    }, [schemas]);
    var onEditModalCell = useCallback(function (cellPos, newValues) {
        editRecordCell(CollectionTableType.TASK_TEMPLATE_MODAL, cellPos, newValues);
    }, [editRecordCell]);
    var onToggleTaskSchemaVisibility = useCallback(function (schemaId) {
        setCustomFields(customFields.map(function (field) {
            if (field.schemaId !== schemaId)
                return field;
            return __assign(__assign({}, field), { visible: !field.visible });
        }));
    }, [customFields, setCustomFields]);
    var onAddColumn = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (schemaId) {
            if (!recordData || !taskCollectionId)
                return;
            var newRecordData = cloneDeep(recordData);
            newRecordData[schemaId] = {
                content: null,
                options: [],
                files: [],
                versionedOptions: [],
            };
            set(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: selectedTaskId })), newRecordData);
            setCustomFields(__spreadArray(__spreadArray([], customFields, true), [{ schemaId: schemaId, visible: true }], false));
            onEditModalCell({
                collectionId: taskCollectionId,
                recordId: selectedTaskId,
                schemaId: schemaId,
            }, []);
        };
    }, [
        recordData,
        taskCollectionId,
        dataSource,
        selectedTaskId,
        setCustomFields,
        customFields,
        onEditModalCell,
    ]);
    var onDeleteColumn = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (schemaId) {
            if (!recordData)
                return;
            var newRecordData = cloneDeep(recordData);
            delete newRecordData[schemaId];
            set(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: selectedTaskId })), newRecordData);
            setSchemasToMutate(function (prev) {
                var newSchemaToMutate = prev.filter(function (schema) { return schema.schemaId !== schemaId; });
                return newSchemaToMutate;
            });
            setCustomFields(customFields.filter(function (field) { return field.schemaId !== schemaId; }));
        };
    }, [recordData, dataSource, selectedTaskId, setSchemasToMutate, setCustomFields, customFields]);
    var reorder = useCallback(function (list, startIndex, endIndex) {
        var result = Array.from(list);
        var removed = result.splice(startIndex, 1)[0];
        result.splice(endIndex, 0, removed);
        return result;
    }, []);
    var onDragEnd = useCallback(function (_a) {
        var source = _a.source, destination = _a.destination;
        if (!destination) {
            return;
        }
        setCustomFields(reorder(customFields, source.index, destination.index));
    }, [customFields, reorder, setCustomFields]);
    var dropdownMenuItems = useMemo(function () {
        return availableSchemas.map(function (schema) {
            return {
                key: schema.id,
                label: schema.name,
                onClick: function () { return onAddColumn(schema.id); },
                disabled: !!customFields.find(function (field) { return field.schemaId === schema.id; }),
            };
        });
    }, [availableSchemas, customFields, onAddColumn]);
    return (_jsxs("div", { className: "CollectionColumnsSection", children: [_jsx("div", { className: "TaskSectionLabel", children: "Custom Fields" }), _jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx(Droppable, { droppableId: "customFieldList", children: function (provided) { return (_jsxs("div", __assign({ ref: provided.innerRef }, provided.droppableProps, { children: [customFields.map(function (_a, index) {
                                var schemaId = _a.schemaId;
                                var schema = availableSchemas.find(function (schema) { return schema.id === schemaId; });
                                var customField = customFields.find(function (field) { return field.schemaId === schemaId; });
                                if (schema && customField) {
                                    return (_jsx(TaskTemplateCustomFieldRow, { index: index, customField: customField, schema: schema, taskCollectionId: taskCollectionId, dataSourceRid: selectedTaskId, onEditModalCell: onEditModalCell, onToggleTaskSchemaVisibility: onToggleTaskSchemaVisibility, onRemoveTaskSchema: onDeleteColumn }, schema.id));
                                }
                                else {
                                    if (schemasFetched && !!schemas) {
                                        // Remove deleted/invalid schema from custom fields
                                        onDeleteColumn(schemaId);
                                    }
                                    //! Hidden draggable so correct index is preserved
                                    return (_jsx(Draggable, { draggableId: schemaId, index: index, children: function (provided) { return (_jsx("div", __assign({}, provided.draggableProps, provided.dragHandleProps, { ref: provided.innerRef }))); } }, schemaId));
                                }
                            }), provided.placeholder] }))); } }) }), taskCollectionId && (_jsx(Dropdown, { menu: { items: dropdownMenuItems }, trigger: ['click'], overlayClassName: "CollectionSchemaDropdownMenu", dropdownRender: function (menu) {
                    return dropdownMenuItems.length > 0 ? (menu) : (_jsx("div", { className: "shadow-md p-2 text-sm font-medium text-gray-400", children: "No custom fields found" }));
                }, children: _jsx(Button, { className: "AddNewCollectionColumnButton", icon: _jsx(PlusOutlined, { style: {
                            color: 'black',
                            fontSize: '14px',
                            stroke: 'black',
                            strokeWidth: '10px',
                            marginBottom: 2,
                        } }), children: "Add Custom Field" }) }))] }));
};
export default React.memo(TaskTemplateCustomFields);
