var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tabs } from 'antd';
import React, { useCallback, useMemo } from 'react';
import SettingsState from 'src/hooks/state/Settings';
import { LeftArrowIcon } from 'src/utils/icons/AccessGroupIcons/LeftArrowIcon';
import AccessManagement from './AccessDetailTabs/AccessManagement';
import DataAccess from './AccessDetailTabs/DataAccess';
import MobileSettings from './AccessDetailTabs/MobileSettings/MobileSettings';
import WorkspaceAcccess from './AccessDetailTabs/WorkspaceAcccess';
import './AccessGroupDetail.scss';
var AccessGroupDetail = function () {
    var _a = SettingsState.useContainer(), accessGroupDetail = _a.accessGroupDetail, setAccessGroupDetail = _a.setAccessGroupDetail, getAccountAccessGroups = _a.getAccountAccessGroups;
    var onOwnerExtraList = (accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.type) != 'owner'
        ? [
            {
                label: "Workspace",
                key: 'workspace',
                children: _jsx(WorkspaceAcccess, {}),
            },
            {
                label: "Data",
                key: 'dataAccess',
                children: _jsx(DataAccess, {}),
            },
        ]
        : [];
    var hasMobileAccessTurnedOn = useMemo(function () { return accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.generalPermissions.ACCESS_MOBILE; }, [accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.generalPermissions.ACCESS_MOBILE]);
    var onMobileExtraList = hasMobileAccessTurnedOn
        ? [
            {
                label: "Mobile Settings",
                key: 'mobileSettings',
                children: _jsx(MobileSettings, {}),
            },
        ]
        : [];
    var handleGoBackToList = useCallback(function () {
        getAccountAccessGroups();
        setAccessGroupDetail(undefined);
    }, [getAccountAccessGroups, setAccessGroupDetail]);
    return (_jsx("div", { className: "AccessGroupDetail", children: _jsx("div", { className: "SettingsPageContainer", children: _jsx("div", { className: "SettingContentView", id: "SettingContentView", style: { padding: '0px 5rem 0px 0px', overflow: 'hidden' }, children: _jsxs("div", { className: "MainSettings", children: [_jsxs("div", { className: "m-6 flex items-center gap-x-3", children: [_jsx("div", { onClick: handleGoBackToList, className: 'hover:cursor-pointer', children: _jsx(LeftArrowIcon, { style: { fontSize: '2em' } }) }), _jsxs("span", { className: "ml-4 text-3xl", children: [_jsxs("span", { className: "hover:cursor-pointer", onClick: handleGoBackToList, children: ["Access Groups /", ' '] }), _jsx("span", { className: "cursor-default font-medium", children: accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.name })] })] }), _jsx(Tabs, { className: "SettingsTabs ml-20 mr-12", defaultActiveKey: "accessManagement", items: __spreadArray(__spreadArray([
                                {
                                    label: "Access Management",
                                    key: 'accessManagement',
                                    children: _jsx(AccessManagement, {}),
                                }
                            ], onOwnerExtraList, true), onMobileExtraList, true) })] }) }) }) }));
};
export default React.memo(AccessGroupDetail);
