var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { CellInputMode, SelectionTypeSet, SubMenuContentIcon, ValueDataType, } from 'src/shared';
import { collectionTableCellDataSelector, collectionTableSchemaSelector } from 'src/state';
import { getColumnTypeKey } from 'src/utils/Collection';
import { Button } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { getFileDisplayValue, getFileUploadPreview, } from 'src/components/Settings/Tasks/TaskTemplate/util';
import { PreFetchedCellHOC } from '../CellHOC';
import { canEditCell } from '../util';
import './ModalCellWrapper.scss';
var ModalCellWrapper = function (props) {
    var cellPos = props.cellPos, collectionTableType = props.collectionTableType, style = props.style, extraContent = props.extraContent, expandedContent = props.expandedContent, _a = props.isMobilePreview, isMobilePreview = _a === void 0 ? false : _a;
    var collectionId = cellPos.collectionId, schemaId = cellPos.schemaId;
    var _b = useState(false), isClicked = _b[0], setIsClicked = _b[1];
    var _c = useState(false), expandContent = _c[0], setExpandContent = _c[1];
    var schema = useRecoilValue(collectionTableSchemaSelector({ collectionId: collectionId, schemaId: schemaId }));
    var _d = schema !== null && schema !== void 0 ? schema : {}, _e = _d.canEdit, canEditDefault = _e === void 0 ? true : _e, _f = _d.name, name = _f === void 0 ? '' : _f, inputMode = _d.inputMode, _g = _d.isRequired, isRequired = _g === void 0 ? false : _g, type = _d.type, nativePath = _d.nativePath, _h = _d.canOnlyUpdateEmpty, canOnlyUpdateEmpty = _h === void 0 ? false : _h;
    var cellValue = useRecoilValue(collectionTableCellDataSelector(__assign({ type: collectionTableType }, cellPos)));
    var canEdit = canEditCell(canEditDefault, canOnlyUpdateEmpty, collectionTableType, cellValue);
    var columnTypeKey = schema ? getColumnTypeKey(inputMode, type, nativePath) : 'custom';
    var _j = useState(false), showCannotEditMsg = _j[0], setShowCannotEditMsg = _j[1];
    var onFocus = useCallback(function (e) {
        if (!extraContent && canEdit && e.target.className.includes('ModalCellWrapper')) {
            setIsClicked(true);
        }
        if (!canEdit) {
            setShowCannotEditMsg(true);
        }
    }, [canEdit, extraContent]);
    var onBlur = useCallback(function (e) {
        var _a, _b, _c, _d;
        if (canEdit &&
            ((e.target.className.includes('ant-select-selection-search-input') &&
                ((_a = e.relatedTarget) === null || _a === void 0 ? void 0 : _a.className.includes('ModalCellWrapper'))) ||
                (e.target.className.includes('PhoneInput') &&
                    !((_b = e.relatedTarget) === null || _b === void 0 ? void 0 : _b.className.includes('selected-flag'))) ||
                e.target.className.includes('address-input') ||
                (e.target.className.includes('ModalCellWrapper') &&
                    type === ValueDataType.FILE &&
                    !((_c = e.relatedTarget) === null || _c === void 0 ? void 0 : _c.className.includes('ant-upload'))) ||
                ((_d = e.relatedTarget) === null || _d === void 0 ? void 0 : _d.className.includes('ant-collapse-header')))) {
            setTimeout(function () {
                setIsClicked(false);
            }, 0);
        }
        if (!canEdit) {
            setShowCannotEditMsg(false);
        }
    }, [canEdit, type]);
    // This prop/setting is used to render specific styling for "mobile preview" views (i.e. in Task Editor)
    // We render this here (vs separately in a different component) so we can make use of cellValue here and
    // extend the reusability of this wrapper
    if (isMobilePreview) {
        if (cellValue && 'files' in cellValue) {
            if (cellValue.files.data.length > 0) {
                return _jsx(_Fragment, { children: getFileDisplayValue(cellValue.files.data) });
            }
            else {
                return _jsx(_Fragment, { children: getFileUploadPreview() });
            }
        }
    }
    return (_jsxs("div", { className: "ModalCellWrapper".concat(!canEdit ? ' cursor-default border-white bg-white' : ' cursor-pointer').concat(isClicked && canEdit ? ' border-modalAccent bg-white' : '').concat((inputMode && [CellInputMode.SINGLE, CellInputMode.MULTI].includes(inputMode)) ||
            (type && SelectionTypeSet.has(type))
            ? ' bg-white'
            : '', " flex-col justify-center"), style: style, tabIndex: 0, onFocus: onFocus, onBlur: onBlur, onMouseLeave: function () {
            if (!canEdit)
                setShowCannotEditMsg(false);
        }, children: [_jsxs("div", { className: "items-center flex w-full", children: [_jsx("div", { className: "CellIcon", children: SubMenuContentIcon[columnTypeKey] }), _jsx("span", { className: "SchemaName", children: name }), _jsx("div", { className: "CellContent".concat(isRequired && canEdit ? ' pr-20' : ''), children: _jsx(PreFetchedCellHOC, __assign({}, props, { value: cellValue, canEdit: canEdit, canOnlyUpdateEmpty: canOnlyUpdateEmpty, isClicked: isClicked, setIsClicked: setIsClicked, blockId: "" })) }), isRequired && canEdit && !isMobilePreview && (_jsx("span", { className: "SchemaRequired", children: "Required" })), !canEdit && showCannotEditMsg && (_jsx("span", { className: "CannotEdit", children: inputMode === CellInputMode.GENERATED
                            ? 'This field is not editable'
                            : 'You do not have permission to edit this field' })), extraContent, !!expandedContent && (_jsxs(Button, { onClick: function (e) {
                            e.stopPropagation();
                            setExpandContent(!expandContent);
                        }, className: "text-xs font-semibold flex items-center mr-1", children: [expandContent ? 'Hide Breakdown' : 'Show Breakdown', expandContent ? _jsx(UpOutlined, {}) : _jsx(DownOutlined, {})] }))] }), expandContent && _jsx("div", { className: "w-full", children: expandedContent })] }));
};
export default React.memo(ModalCellWrapper);
