import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useMemo } from 'react';
import HardConfirmWrapper from 'src/components/HardConfirmWrapper/HardConfirmWrapper';
var IndividualRateOverrideModal = function (_a) {
    var isModalVisible = _a.isModalVisible, confirmApplyToIndividualPaySettings = _a.confirmApplyToIndividualPaySettings, cancelApplyToIndividualPaySettings = _a.cancelApplyToIndividualPaySettings;
    var footer = useMemo(function () {
        return (_jsxs("div", { className: "py-2", children: [_jsx(Button, { className: "ZiraModalCancelBtn", onClick: cancelApplyToIndividualPaySettings, children: "No" }), _jsx(HardConfirmWrapper, { changeType: "override", entityName: "individual rates", onConfirm: confirmApplyToIndividualPaySettings, hasPluralEntityName: true, children: _jsx(Button, { className: "ZiraModalDeleteBtn", children: "Override" }) })] }));
    }, [cancelApplyToIndividualPaySettings, confirmApplyToIndividualPaySettings]);
    return (_jsxs(Modal, { open: isModalVisible, centered: true, onOk: confirmApplyToIndividualPaySettings, onCancel: cancelApplyToIndividualPaySettings, footer: footer, children: [_jsx("div", { className: "mt-5 font-medium", children: "Are you sure you want to override existing individual pay settings?" }), _jsxs("div", { className: "flex items-start justify-between rounded bg-red-50 p-4 text-red-500 mt-2", children: [_jsx(ExclamationCircleOutlined, { style: {
                            color: '#B3342F',
                            fontSize: '20px',
                        }, size: 24 }), _jsxs("div", { className: "ml-2 font-medium", children: [_jsx("span", { className: "font-semibold", children: "Warning" }), _jsx("br", {}), "This action will override existing individual rates. Please proceed with caution."] })] })] }));
};
export default React.memo(IndividualRateOverrideModal);
