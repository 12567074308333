import { jsx as _jsx } from "react/jsx-runtime";
import { Dropdown } from 'antd';
import { useCallback, useState } from 'react';
var MobileRequireApprovalDropdown = function (_a) {
    var defaultRequired = _a.defaultRequired, onChange = _a.onChange;
    var _b = useState(defaultRequired), requireApproval = _b[0], setRequireApproval = _b[1];
    var requireManagerApprovalText = 'Require manager approval';
    var nonRequireManagerApprovalText = "Doesn't require manager approval";
    var titleText = requireApproval ? requireManagerApprovalText : nonRequireManagerApprovalText;
    var handleSetRequireApproval = useCallback(function (require) {
        setRequireApproval(require);
        onChange(require);
    }, [onChange]);
    var items = [
        {
            key: 'require manage approval',
            label: requireManagerApprovalText,
            onClick: function () {
                handleSetRequireApproval(true);
            },
        },
        {
            key: 'no require manage approval',
            label: nonRequireManagerApprovalText,
            onClick: function () {
                handleSetRequireApproval(false);
            },
        },
    ];
    return (_jsx(Dropdown, { menu: { items: items }, trigger: ['click'], children: _jsx("div", { className: "rounded bg-gray-50 px-3 py-1 font-medium hover:cursor-pointer", children: titleText }) }));
};
export default MobileRequireApprovalDropdown;
