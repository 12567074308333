var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CollectionTableType, CollectionType } from 'src/shared';
import { useRecordComponent } from 'src/hooks/component';
import { collectionTableState, collectionTableRecordState, collectionTableSchemaMutationsState, } from 'src/state';
import { useTasksApi } from 'src/hooks/api/useTasksApi';
import { useAccountApi } from 'src/hooks/api';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ProfileTaskSchemaType } from '../../interface';
import { NEW_TASK_TEMPLATE_ID } from '../../constant';
import { isTaskSchemaRequired } from '../util';
import EmptyRequiredDataContent from './EmptyRequiredDataContent';
import ProfileDataCollectionSelector from './ProfileDataCollectionSelector';
import AddFieldDropdown from './AddFieldDropdown';
import ProfileDataTaskSchemaRow from './ProfileDataTaskSchemaRow';
var ProfileDataTypeTaskSettingSection = function (_a) {
    var selectedTaskId = _a.selectedTaskId, profileData = _a.profileData, updateProfileData = _a.updateProfileData;
    var useGetUserReverseCollections = useTasksApi().useGetUserReverseCollections;
    var useGetSchemaColumnsInCollection = useAccountApi().useGetSchemaColumnsInCollection;
    var editRecordCell = useRecordComponent().editRecordCell;
    var allCollections = useGetUserReverseCollections().data;
    var selectedCollectionId = profileData.collectionId;
    var selectedSchemaId = profileData.schemaId;
    var collectionInfo = useMemo(function () {
        if (!allCollections || !selectedCollectionId)
            return undefined;
        return allCollections.reverseCollections.find(function (collection) { return collection.collectionId === selectedCollectionId; });
    }, [allCollections, selectedCollectionId]);
    var dataSource = useMemo(function () { return ({
        type: CollectionTableType.TASK_TEMPLATE_MODAL,
        collectionId: selectedCollectionId !== null && selectedCollectionId !== void 0 ? selectedCollectionId : '',
    }); }, [selectedCollectionId]);
    var isCreatingNew = selectedTaskId === NEW_TASK_TEMPLATE_ID;
    var setCollectionTableData = useSetRecoilState(collectionTableState(dataSource));
    //! Profile state management
    var setSchemasToMutate = useSetRecoilState(collectionTableSchemaMutationsState(dataSource));
    var recordData = useRecoilValue(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: selectedTaskId })));
    var _b = useGetSchemaColumnsInCollection({
        collectionId: selectedCollectionId !== null && selectedCollectionId !== void 0 ? selectedCollectionId : '',
        options: {
            enabled: !!selectedCollectionId,
        },
    }), schemas = _b.data, schemasFetched = _b.isFetched;
    var _c = useState(false), isInitialized = _c[0], setIsInitialized = _c[1];
    useEffect(function () {
        setIsInitialized(false);
    }, [selectedTaskId]);
    //! Initialize records & schema mutations state
    useEffect(function () {
        if (isInitialized ||
            !selectedTaskId ||
            !selectedCollectionId ||
            !(schemas === null || schemas === void 0 ? void 0 : schemas.columns) ||
            !collectionInfo) {
            return;
        }
        var initializeState = function () {
            var _a, _b;
            var _c, _d;
            var cellData = {};
            if (isCreatingNew) {
                var taskModalRecord = recordData !== null && recordData !== void 0 ? recordData : {};
                cellData = (_a = {}, _a[selectedTaskId] = taskModalRecord, _a);
            }
            else {
                var templateModalRecord = (_c = profileData.cells) !== null && _c !== void 0 ? _c : {};
                cellData = (_b = {},
                    _b[selectedTaskId] = templateModalRecord,
                    _b);
            }
            setCollectionTableData({
                collectionInfo: {
                    id: selectedCollectionId,
                    type: collectionInfo.collectionType,
                    name: collectionInfo.title,
                },
                columns: schemas.columns,
                rows: [],
                cells: cellData,
                nonSchemaNativeFieldsMap: {},
                permissions: { canEditCollection: false, canEditSchemas: false },
            });
            setSchemasToMutate((_d = profileData.taskSchemas) === null || _d === void 0 ? void 0 : _d.filter(function (schema) { return schema.defaultValue; }).map(function (schema) { return schema.defaultValue; }));
            setIsInitialized(true);
        };
        initializeState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized, selectedTaskId, selectedCollectionId, schemas, collectionInfo]);
    //! Reset state on unmount
    useEffect(function () {
        return function () {
            setCollectionTableData(undefined);
            setSchemasToMutate([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //! Reset state on reverse collection change
    useEffect(function () {
        if (isInitialized) {
            setIsInitialized(false);
            setCollectionTableData(undefined);
            setSchemasToMutate([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData.collectionId, profileData.schemaId]);
    //! Update profile data when recordData changes
    useEffect(function () {
        var _a;
        if (!isEqual(recordData, profileData === null || profileData === void 0 ? void 0 : profileData.cells)) {
            updateProfileData({
                cells: (_a = recordData !== null && recordData !== void 0 ? recordData : profileData === null || profileData === void 0 ? void 0 : profileData.cells) !== null && _a !== void 0 ? _a : {},
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordData]);
    var taskSchemas = useMemo(function () { var _a; return (_a = profileData === null || profileData === void 0 ? void 0 : profileData.taskSchemas) !== null && _a !== void 0 ? _a : []; }, [profileData === null || profileData === void 0 ? void 0 : profileData.taskSchemas]);
    var selectedSchemaIds = useMemo(function () { var _a; return (_a = taskSchemas === null || taskSchemas === void 0 ? void 0 : taskSchemas.map(function (schema) { return schema.schemaId; })) !== null && _a !== void 0 ? _a : []; }, [taskSchemas]);
    var availableSchemas = useMemo(function () {
        if (!schemas) {
            return [];
        }
        else {
            // Filter out user relation schema
            return schemas.columns.filter(function (schema) { return schema.id !== (profileData === null || profileData === void 0 ? void 0 : profileData.schemaId) && schema.canEdit; });
        }
    }, [profileData === null || profileData === void 0 ? void 0 : profileData.schemaId, schemas]);
    var updateTaskSchemas = useCallback(function (newTaskSchemas) {
        updateProfileData({
            taskSchemas: newTaskSchemas,
        });
    }, [updateProfileData]);
    //! Pre-fill required fields for new profile data tasks
    useEffect(function () {
        // Only pre-fill required fields for new tasks for easier state management
        // All profile data tasks create a record (except user profile update; no selectedSchemaId), so we need to add required fields
        if (isCreatingNew && availableSchemas && selectedSchemaId) {
            var requiredSchemas = availableSchemas.filter(function (schema) { return isTaskSchemaRequired(schema) && schema.id !== selectedSchemaId; });
            updateTaskSchemas(requiredSchemas.map(function (schema) { return ({
                schemaId: schema.id,
                taskSchemaType: ProfileTaskSchemaType.REQUIRED,
            }); }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableSchemas, selectedSchemaId]);
    var onEditModalCell = useCallback(function (cellPos, newValues) {
        editRecordCell(CollectionTableType.TASK_TEMPLATE_MODAL, cellPos, newValues);
    }, [editRecordCell]);
    var onAddNewTaskSchema = useCallback(function (schemaId) {
        updateTaskSchemas(taskSchemas.concat({
            schemaId: schemaId,
            taskSchemaType: ProfileTaskSchemaType.REQUIRED,
        }));
    }, [taskSchemas, updateTaskSchemas]);
    var onUpdateTaskSchemaType = useCallback(function (schemaId, type) {
        updateTaskSchemas(taskSchemas.map(function (schema) {
            return schema.schemaId === schemaId ? __assign(__assign({}, schema), { taskSchemaType: type }) : schema;
        }));
    }, [taskSchemas, updateTaskSchemas]);
    var onRemoveTaskSchema = useCallback(function (schemaId) {
        updateTaskSchemas(taskSchemas === null || taskSchemas === void 0 ? void 0 : taskSchemas.filter(function (schema) { return schema.schemaId !== schemaId; }));
    }, [taskSchemas, updateTaskSchemas]);
    var reorder = useCallback(function (list, startIndex, endIndex) {
        var result = Array.from(list);
        var removed = result.splice(startIndex, 1)[0];
        result.splice(endIndex, 0, removed);
        return result;
    }, []);
    var onDragEnd = useCallback(function (_a) {
        var source = _a.source, destination = _a.destination;
        if (!destination) {
            return;
        }
        updateTaskSchemas(reorder(taskSchemas, source.index, destination.index));
    }, [reorder, taskSchemas, updateTaskSchemas]);
    return (_jsx("div", { className: "ProfileDataSettingContent", children: _jsxs("div", { children: [_jsx(ProfileDataCollectionSelector, { reverseCollections: allCollections === null || allCollections === void 0 ? void 0 : allCollections.reverseCollections, selectedCollectionId: selectedCollectionId, selectedSchemaId: selectedSchemaId, updateProfileData: updateProfileData }), selectedCollectionId ? (_jsx("div", { className: "CollectionColumnsSection", children: _jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Form Setup" }), _jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx(Droppable, { droppableId: "list", children: function (provided) { return (_jsxs("div", __assign({ ref: provided.innerRef }, provided.droppableProps, { children: [taskSchemas.map(function (taskSchema, index) {
                                                var schema = availableSchemas.find(function (schema) { return schema.id === taskSchema.schemaId; });
                                                if (schema) {
                                                    return (_jsx(ProfileDataTaskSchemaRow, { taskSchema: taskSchema, schema: schema, recordData: recordData, dataSource: dataSource, selectedCollectionId: selectedCollectionId, isUserProfile: (collectionInfo === null || collectionInfo === void 0 ? void 0 : collectionInfo.collectionType) ===
                                                            CollectionType.USER &&
                                                            !selectedSchemaId, index: index, dataSourceRid: selectedTaskId, onEditModalCell: onEditModalCell, onUpdateTaskSchemaType: onUpdateTaskSchemaType, onRemoveTaskSchema: onRemoveTaskSchema }, schema.id));
                                                }
                                                else {
                                                    if (schemasFetched && !!schemas) {
                                                        // Remove deleted/invalid schema from profile data task schemas
                                                        onRemoveTaskSchema(taskSchema.schemaId);
                                                    }
                                                    //! Hidden draggable so correct index is preserved
                                                    return (_jsx(Draggable, { draggableId: taskSchema.schemaId, index: index, children: function (provided) { return (_jsx("div", __assign({}, provided.draggableProps, provided.dragHandleProps, { ref: provided.innerRef }))); } }, taskSchema.schemaId));
                                                }
                                            }), provided.placeholder] }))); } }) }), _jsx(AddFieldDropdown, { availableSchemas: availableSchemas, selectedSchemaIds: selectedSchemaIds, recordData: recordData, selectedCollectionId: selectedCollectionId, dataSource: dataSource, dataSourceRid: selectedTaskId, onEditModalCell: onEditModalCell, onAddNewTaskSchema: onAddNewTaskSchema })] }) })) : (_jsx(EmptyRequiredDataContent, {}))] }) }));
};
export default React.memo(ProfileDataTypeTaskSettingSection);
