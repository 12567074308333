var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c, _d, _e, _f;
import { jsx as _jsx } from "react/jsx-runtime";
import { LockOutlined } from '@ant-design/icons';
import { DayOfWeek } from 'src/components/Settings/Compliance/Interface';
import { ZipExportFileIcon } from 'src/utils/icons/ZipExportFile';
import { PdfExportFileIcon } from 'src/utils/icons/PdfExportFileIcon';
import { CsvExportFileIcon } from 'src/utils/icons/CsvExportFileIcon';
import { ButtonSchemaOutlinedIcon } from 'src/utils/icons/DropdownIcons/ButtonSchemaOutlined';
import { SparkleOutlinedIcon } from 'src/utils/icons/DropdownIcons/SparkleOutlined';
import { CurrencyOutlineIcon } from 'src/utils/icons/DropdownIcons/CurrencyOutlined';
import { DateOutlinedIcon } from '../utils/icons/DropdownIcons/DateOutlined';
import { FileOutlinedIcon } from '../utils/icons/DropdownIcons/FileOutlined';
import { FormulaOutlinedIcon } from '../utils/icons/DropdownIcons/FormulaOutlined';
import { StartOutlinedIcon } from '../utils/icons/DropdownIcons/StartOutlined';
import { LinkOutlinedIcon } from '../utils/icons/DropdownIcons/LinkOutlined';
import { MultiSelectOutlinedIcon } from '../utils/icons/DropdownIcons/MultiSelectOutlined';
import { NumberlinedIcon } from '../utils/icons/DropdownIcons/NumberOutlined';
import { SingleSelectOutlinedIcon } from '../utils/icons/DropdownIcons/SingleSelectOutlined';
import { TextOutlinedIcon } from '../utils/icons/DropdownIcons/TextOutlined';
import { LocationOutlinedIcon } from '../utils/icons/DropdownIcons/LocationOutlined';
import { PhoneOutlinedIcon } from '../utils/icons/DropdownIcons/PhoneOutlined';
import { SignatureOutlinedIcon } from '../utils/icons/DropdownIcons/SignatureOutlined';
import { RollupOutlineIcon } from '../utils/icons/DropdownIcons/RollupOutlined';
export var TEMP_NODE_TITLE = 'Untitled';
export var SCHEDULE_UNASSIGNED_KEY = 'NO_ASSIGNMENT';
export var DefaultPagePagination = {
    page: 0,
    size: 50,
};
export var DefaultReverseCollectionPagePagination = {
    page: 0,
    size: 20,
};
export var DefaultShiftRequestsPagePagination = {
    page: 0,
    size: 20,
};
export var DefaultBoardViewColumnPageSize = 25;
export var SelectOptionPageSize = 100;
export var ItemDataSource;
(function (ItemDataSource) {
    ItemDataSource[ItemDataSource["ITEMS_DATA"] = 0] = "ITEMS_DATA";
    ItemDataSource[ItemDataSource["SELECTION_VALUES"] = 1] = "SELECTION_VALUES";
    ItemDataSource[ItemDataSource["NATIVE_FIELDS"] = 2] = "NATIVE_FIELDS";
    ItemDataSource[ItemDataSource["RELATIONS"] = 3] = "RELATIONS";
    ItemDataSource[ItemDataSource["RECORD_METRIC_VALUES"] = 4] = "RECORD_METRIC_VALUES";
})(ItemDataSource || (ItemDataSource = {}));
export var SchemaDisplayType;
(function (SchemaDisplayType) {
    SchemaDisplayType[SchemaDisplayType["TEXT"] = 0] = "TEXT";
    SchemaDisplayType[SchemaDisplayType["SELECTION"] = 1] = "SELECTION";
    SchemaDisplayType[SchemaDisplayType["TIME"] = 2] = "TIME";
    SchemaDisplayType[SchemaDisplayType["USER"] = 3] = "USER";
    SchemaDisplayType[SchemaDisplayType["FILES"] = 4] = "FILES";
    SchemaDisplayType[SchemaDisplayType["SIGNATURE"] = 5] = "SIGNATURE";
    SchemaDisplayType[SchemaDisplayType["TITLE"] = 6] = "TITLE";
    SchemaDisplayType[SchemaDisplayType["FORMULA"] = 7] = "FORMULA";
    SchemaDisplayType[SchemaDisplayType["BUTTON"] = 8] = "BUTTON";
    SchemaDisplayType[SchemaDisplayType["ROLLUP"] = 9] = "ROLLUP";
})(SchemaDisplayType || (SchemaDisplayType = {}));
export var StandardObject;
(function (StandardObject) {
    StandardObject[StandardObject["USER"] = 0] = "USER";
    StandardObject[StandardObject["SHIFT"] = 1] = "SHIFT";
    StandardObject[StandardObject["BREAKS"] = 2] = "BREAKS";
    StandardObject[StandardObject["TIMEOFF"] = 3] = "TIMEOFF";
    StandardObject[StandardObject["AUTOMATION"] = 4] = "AUTOMATION";
    StandardObject[StandardObject["LOCATION"] = 5] = "LOCATION";
    StandardObject[StandardObject["ROLE"] = 6] = "ROLE";
})(StandardObject || (StandardObject = {}));
export var FileType;
(function (FileType) {
    FileType[FileType["IMAGE"] = 0] = "IMAGE";
    FileType[FileType["DOCUMENT"] = 1] = "DOCUMENT";
})(FileType || (FileType = {}));
export var OPTIONS_COLOR = [
    '#EAEDFC',
    '#F3F1FB',
    '#E8F2FC',
    '#E5F4EF',
    '#FFF9ED',
    '#FFF1EB',
    '#FFEDEC',
    '#F5EFEC',
    '#F4F4F7',
];
export var OPTIONS_COLOR_NAME = [
    'Default',
    'Purple',
    'Blue',
    'Green',
    'Yellow',
    'Orange',
    'Red',
    'Brown',
    'Gray',
];
export var OPTIONS_COLOR_MAP = {
    Default: '#EAEDFC',
    Purple: '#F3F1FB',
    Blue: '#E8F2FC',
    Green: '#E5F4EF',
    Yellow: '#FFF9ED',
    Orange: '#FFF1EB',
    Red: '#FFEDEC',
    Brown: '#F5EFEC',
    Gray: '#F4F4F7',
};
export var EmitterEventType;
(function (EmitterEventType) {
    EmitterEventType["NEW_COLUMN_CREATED"] = "create_new_column";
    EmitterEventType["OPEN_DETAIL_MODAL"] = "open_detail_modal";
    // Edit, Create, Delete Record Event
    EmitterEventType["REFETCH_COLLECTION"] = "refetch_collection";
    EmitterEventType["REFRESH_SCHEDULE"] = "refresh_schedule";
    EmitterEventType["REFETCH_DASHBOARD_SNAPSHOT"] = "refetch_dashboard_snapshot";
    EmitterEventType["REFETCH_PAYCARD_LIST"] = "refetch_paycard_list";
})(EmitterEventType || (EmitterEventType = {}));
export var ButtonType;
(function (ButtonType) {
    ButtonType["PAGE"] = "PAGE";
    ButtonType["FORM"] = "FORM";
    ButtonType["WEB_LINK"] = "WEB_LINK";
})(ButtonType || (ButtonType = {}));
export var PostSenderType;
(function (PostSenderType) {
    PostSenderType["USER"] = "USER";
    PostSenderType["SYSTEM"] = "SYSTEM";
})(PostSenderType || (PostSenderType = {}));
export var PostMessageType;
(function (PostMessageType) {
    PostMessageType["BASIC"] = "BASIC";
})(PostMessageType || (PostMessageType = {}));
export var TransactionTableNameMap;
(function (TransactionTableNameMap) {
    TransactionTableNameMap["ACCOUNT"] = "account";
    TransactionTableNameMap["COLLECTION"] = "collection";
    TransactionTableNameMap["COLLECTION_ITEM"] = "collection_item";
    TransactionTableNameMap["COLLECTION_RECORD"] = "collection_record";
    TransactionTableNameMap["COLLECTION_SCHEMA"] = "collection_schema";
    TransactionTableNameMap["COLLECTION_VALUE"] = "collection_value";
    TransactionTableNameMap["PAGE"] = "page";
    TransactionTableNameMap["PAGE_GROUP"] = "page_group";
    TransactionTableNameMap["PAGE_BLOCK"] = "block";
    TransactionTableNameMap["COLLECTION_VIEWS"] = "collection_view";
    TransactionTableNameMap["SHIFT"] = "shift";
    TransactionTableNameMap["USER"] = "user";
})(TransactionTableNameMap || (TransactionTableNameMap = {}));
export var AccountTemplateName;
(function (AccountTemplateName) {
    AccountTemplateName["BLANK_TEMPLATE"] = "blank_template";
    AccountTemplateName["HEALTHCARE_STAFFING"] = "healthcare_staffing";
})(AccountTemplateName || (AccountTemplateName = {}));
export var isNaviTreeShowableBlockContentType = function (blockContentType) {
    return blockContentType === 'collection' ||
        blockContentType === 'scheduling' ||
        blockContentType === 'report' ||
        blockContentType === 'shift_requests' ||
        blockContentType === 'release_requests';
};
export var CollectionType;
(function (CollectionType) {
    CollectionType["CUSTOM"] = "custom";
    CollectionType["USER"] = "user";
    CollectionType["TIMEOFF"] = "timeoff";
    CollectionType["SHIFT"] = "shift";
    CollectionType["BREAK"] = "break";
    CollectionType["LOCATION"] = "location";
    CollectionType["REPORT"] = "report";
    CollectionType["ROLE"] = "role";
    CollectionType["PAY_PERIOD"] = "pay_periods";
    CollectionType["CONTACT"] = "contact";
    CollectionType["PROJECT"] = "project";
    CollectionType["DOCUMENT"] = "document";
    CollectionType["TASK"] = "task";
    CollectionType["SHIFT_GROUP"] = "shift_group";
    CollectionType["PLACEMENT"] = "placement";
    CollectionType["JOB"] = "job";
})(CollectionType || (CollectionType = {}));
export var CellInputMode;
(function (CellInputMode) {
    CellInputMode["SINGLE"] = "single";
    CellInputMode["MULTI"] = "multi";
    CellInputMode["PLAIN"] = "plain";
    CellInputMode["NATIVE"] = "native";
    CellInputMode["INTEGRATION"] = "integration";
    CellInputMode["METRIC"] = "metric";
    CellInputMode["GENERATED"] = "generated";
    CellInputMode["USER_DEFINED_GENERATED"] = "user_defined_generated";
})(CellInputMode || (CellInputMode = {}));
export var SchemaConfigurationType;
(function (SchemaConfigurationType) {
    SchemaConfigurationType["DATETIME"] = "DATETIME";
    SchemaConfigurationType["SELECT"] = "SELECT";
    SchemaConfigurationType["BUTTON"] = "BUTTON";
    SchemaConfigurationType["GENERATED_ID"] = "GENERATED_ID";
    SchemaConfigurationType["GENERATIVE_TEXT"] = "GENERATIVE_TEXT";
    SchemaConfigurationType["GENERATIVE_SELECT"] = "GENERATIVE_SELECT";
    SchemaConfigurationType["CURRENCY"] = "CURRENCY";
})(SchemaConfigurationType || (SchemaConfigurationType = {}));
export var CurrencySchemaType;
(function (CurrencySchemaType) {
    CurrencySchemaType["NONE"] = "NONE";
    CurrencySchemaType["HOURLY_PAY"] = "HOURLY_PAY";
    CurrencySchemaType["HOURLY_BILL"] = "HOURLY_BILL";
    CurrencySchemaType["FIXED_PAY"] = "FIXED_PAY";
    CurrencySchemaType["FIXED_BILL"] = "FIXED_BILL";
})(CurrencySchemaType || (CurrencySchemaType = {}));
export var QualifierType;
(function (QualifierType) {
    QualifierType["COLLECTION"] = "COLLECTION";
    QualifierType["SELECTION"] = "SELECTION";
    QualifierType["TIME"] = "TIME";
})(QualifierType || (QualifierType = {}));
export var IpName;
(function (IpName) {
    IpName["SQUARE"] = "square";
    IpName["ADP"] = "adp";
    IpName["GUSTO"] = "gusto";
    IpName["DOCUSIGN"] = "docusign";
    IpName["RIPPLING"] = "rippling";
    IpName["SIGNNOW"] = "signnow";
    IpName["QUICKBOOKS"] = "quickbooks";
    IpName["PAYCHEX"] = "paychex";
    IpName["CHECKRPAY"] = "checkrpay";
    IpName["SmartRecruiters"] = "smartrecruiters";
    IpName["KAMANA"] = "kamana";
    IpName["TITAN"] = "titan";
    IpName["BULLHORN"] = "bullhorn";
})(IpName || (IpName = {}));
export var HoursSelectorsType = ['REGULAR', 'OVERTIME', 'DOUBLE_OVERTIME'];
export var ValueDataType;
(function (ValueDataType) {
    // title
    ValueDataType["TITLE"] = "title";
    // primitive
    ValueDataType["TEXT"] = "text";
    ValueDataType["PLAIN_SELECTION"] = "plain_selection";
    ValueDataType["NAME"] = "name";
    ValueDataType["NUMBER"] = "number";
    ValueDataType["PHONE"] = "phone";
    ValueDataType["CURRENCY"] = "currency";
    ValueDataType["DATETIME"] = "datetime";
    ValueDataType["GEOFENCE"] = "geofences";
    ValueDataType["DATETIME_RANGE"] = "datetime_range";
    ValueDataType["BOOLEAN"] = "boolean";
    ValueDataType["BUTTON"] = "button";
    ValueDataType["FILE"] = "file";
    ValueDataType["GENERATED"] = "generated";
    ValueDataType["SIGNATURE"] = "signature";
    ValueDataType["FORMULA"] = "formula";
    ValueDataType["ROLLUP"] = "rollup";
    // relations
    ValueDataType["CUSTOM"] = "custom";
    ValueDataType["SHIFT"] = "shift";
    ValueDataType["USER"] = "user";
    ValueDataType["LOCATION"] = "location";
    ValueDataType["ROLE"] = "role";
    ValueDataType["PAY_PERIOD"] = "pay_periods";
    ValueDataType["CONTACT"] = "contact";
    ValueDataType["SHIFT_GROUP"] = "shift_group";
    ValueDataType["JOB"] = "job";
    ValueDataType["PLACEMENT"] = "placement";
    // standard objs
    ValueDataType["INTEGRATION"] = "integration";
    // customized types
    ValueDataType["EMAIL"] = "email";
    ValueDataType["URL"] = "url";
    // native
    ValueDataType["NATIVE_BOOL"] = "native_bool";
    ValueDataType["NATIVE_NUMBER"] = "native_number";
    ValueDataType["NATIVE_TEXT"] = "native_text";
    ValueDataType["NATIVE_NAME"] = "native_name";
    ValueDataType["NATIVE_DATETIME"] = "native_datetime";
    ValueDataType["NATIVE_CURRENCY"] = "native_currency";
    ValueDataType["NATIVE_PHONE"] = "native_phone";
    ValueDataType["NATIVE_EMAIL"] = "native_email";
    ValueDataType["NATIVE_URL"] = "native_url";
    ValueDataType["NATIVE_USER_ID"] = "native_user_id";
    ValueDataType["NATIVE_USER_IDS"] = "native_user_ids";
    ValueDataType["NATIVE_TYPE_ID"] = "native_type_id";
    ValueDataType["NATIVE_LOCATION_ID"] = "native_location_id";
    ValueDataType["NATIVE_LOCATION_IDS"] = "native_location_ids";
    ValueDataType["NATIVE_ACCESS_GROUP_IDS"] = "native_user_access_group_ids";
    ValueDataType["NATIVE_SPECIAL"] = "native_special";
    ValueDataType["NATIVE_ROLE_IDS"] = "native_role_ids";
    ValueDataType["NATIVE_TIMEOFF_POLICY_ID"] = "native_timeoff_policy_id";
    ValueDataType["NATIVE_OVERTIME_POLICY_ID"] = "native_overtime_policy_id";
    ValueDataType["NATIVE_BREAK_POLICY_ID"] = "native_break_policy_id";
    ValueDataType["NATIVE_BREAK_RULE_ID"] = "native_break_rule_id";
    ValueDataType["NATIVE_HOLIDAY_POLICY_ID"] = "native_holiday_policy_id";
    ValueDataType["NATIVE_GEOFENCE_ID"] = "native_geofence_id";
    ValueDataType["NATIVE_GENERATED"] = "native_generated";
    ValueDataType["NATIVE_USER_PIN"] = "native_user_pin";
    ValueDataType["NATIVE_DURATION"] = "native_duration";
    ValueDataType["NATIVE_SELECTION"] = "native_selection";
    ValueDataType["NATIVE_TEMPLATE_ID"] = "native_template_id";
    ValueDataType["NATIVE_GROUP_ID"] = "native_group_id";
    ValueDataType["NATIVE_JOB_RID"] = "native_job_rid";
    ValueDataType["NATIVE_PLACEMENT_RID"] = "native_placement_rid";
    ValueDataType["NATIVE_SHIFT_RID"] = "native_shift_rid";
    // metric
    ValueDataType["METRIC"] = "metric";
    ValueDataType["METRIC_CURRENCY"] = "metric_currency";
    ValueDataType["METRIC_PERCENTAGE"] = "metric_percentage";
    ValueDataType["RECORD_METRIC"] = "record_metric";
    // generative
    ValueDataType["GENERATED_ID"] = "generated_id";
})(ValueDataType || (ValueDataType = {}));
export var minColumnWidth = 100;
export var defaultColumnWidth = 200;
export var printColumnWidth = 125;
export var printTitleColumnWidth = 250;
export var ItemRelationTypeSet = new Set([
    ValueDataType.SHIFT,
    ValueDataType.USER,
    ValueDataType.CUSTOM,
    ValueDataType.LOCATION,
    ValueDataType.ROLE,
    ValueDataType.PAY_PERIOD,
    ValueDataType.NATIVE_TIMEOFF_POLICY_ID,
    ValueDataType.NATIVE_OVERTIME_POLICY_ID,
    ValueDataType.NATIVE_BREAK_POLICY_ID,
    ValueDataType.NATIVE_BREAK_RULE_ID,
    ValueDataType.NATIVE_TYPE_ID,
    ValueDataType.CONTACT,
]);
export var DefaultDisplaySettingsObj = {
    sortBy: [],
    filters: [],
    schemaOptions: [],
    groupSetting: null,
};
export var DefaultEmptyTitleObj = {
    type: 'title',
    inputMode: 'plain',
    title: TEMP_NODE_TITLE,
};
export var NaviTreeChildColumnName;
(function (NaviTreeChildColumnName) {
    NaviTreeChildColumnName["GROUP"] = "page_ids";
    NaviTreeChildColumnName["PAGE"] = "collection_ids";
})(NaviTreeChildColumnName || (NaviTreeChildColumnName = {}));
export var SortSettingClause;
(function (SortSettingClause) {
    SortSettingClause["ASC"] = "ASC";
    SortSettingClause["DESC"] = "DESC";
})(SortSettingClause || (SortSettingClause = {}));
export var RecordSchemaType;
(function (RecordSchemaType) {
    RecordSchemaType["TIMESTAMP"] = "timestamp";
    RecordSchemaType["UUID"] = "uuid";
    RecordSchemaType["UUIDS"] = "uuids";
    RecordSchemaType["TEXT"] = "text";
    RecordSchemaType["BOOLEAN"] = "boolean";
    RecordSchemaType["CONTENT_ID"] = "contentId";
    RecordSchemaType["NUMBER"] = "number";
    RecordSchemaType["AMOUNT"] = "amount";
    RecordSchemaType["PHONE"] = "phone";
    RecordSchemaType["EMAIL"] = "email";
    RecordSchemaType["flex"] = "flex";
    RecordSchemaType["COLLECTION_VALUE"] = "collectionValue";
    RecordSchemaType["RECORD_ID"] = "recordId";
    RecordSchemaType["FILE"] = "file";
    RecordSchemaType["NAME"] = "name";
    RecordSchemaType["LOCAL_DATE"] = "localDate";
    RecordSchemaType["STYLE"] = "style";
    RecordSchemaType["DURATION"] = "duration";
})(RecordSchemaType || (RecordSchemaType = {}));
export var NativePath;
(function (NativePath) {
    // user
    NativePath["USER_FIRST_NAME"] = "user.first_name";
    NativePath["USER_LAST_NAME"] = "user.last_name";
    NativePath["USER_EMAIL"] = "user.email";
    NativePath["USER_PHONE"] = "user.phone";
    NativePath["USER_ARCHIVED"] = "user.archived";
    NativePath["USER_ROLES"] = "user_roles.role_rid";
    NativePath["USER_LOCATIONS"] = "user_locations.location_rid";
    NativePath["USER_ACCESS_GROUPS"] = "user.access_group";
    NativePath["USER_DOB"] = "user.date_of_birth";
    NativePath["USER_LAST_ACTIVE"] = "user.last_active";
    NativePath["USER_PIN"] = "user.pin";
    // break
    NativePath["BREAK_CLOCK_IN"] = "break.clock_in";
    NativePath["BREAK_CLOCK_OUT"] = "break.clock_out";
    NativePath["BREAK_RULE"] = "break.rule_id";
    NativePath["BREAK_SCHEDULED_DURATION"] = "break.scheduled_duration";
    NativePath["BREAK_SCHEDULED_START_AT"] = "break.scheduled_start_at";
    // time off
    NativePath["TIMEOFF_USER_ID"] = "timeoff.user_rid";
    NativePath["TIMEOFF_START_AT"] = "timeoff.start_at";
    NativePath["TIMEOFF_END_AT"] = "timeoff.end_at";
    NativePath["TIMEOFF_TYPE_ID"] = "timeoff.type_id";
    NativePath["TIMEOFF_APPROVAL_STATUS"] = "timeoff.approval_status";
    // shift
    NativePath["SHIFT_START_AT"] = "shift.start_at";
    NativePath["SHIFT_END_AT"] = "shift.end_at";
    NativePath["SHIFT_USER_ID"] = "shift.user_id";
    NativePath["SHIFT_LOCATION_ID"] = "shift.location_rid";
    NativePath["SHIFT_ROLE_IDS"] = "shift.role_rids";
    NativePath["SHIFT_CLOCK_IN"] = "shift.clock_in";
    NativePath["SHIFT_CLOCK_OUT"] = "shift.clock_out";
    NativePath["SHIFT_WAGE"] = "shift.wage";
    NativePath["SHIFT_BONUS"] = "shift.bonus";
    NativePath["SHIFT_BILL_BONUS"] = "shift.bill_bonus";
    NativePath["SHIFT_PUBLISHED"] = "shift.published";
    NativePath["SHIFT_ARCHIVED"] = "shift.archived";
    NativePath["SHIFT_STATUS"] = "shift.status";
    NativePath["SHIFT_HOURS_WORKED"] = "shift.hours_worked";
    NativePath["SHIFT_HOURS_SCHEDULED"] = "shift.hours_scheduled";
    NativePath["SHIFT_REGULAR_HOURS"] = "shift.regular_hours";
    NativePath["SHIFT_REGULAR_PAY"] = "shift.regular_pay";
    NativePath["SHIFT_SINGLE_OVERTIME_HOURS"] = "shift.single_overtime_hours";
    NativePath["SHIFT_SINGLE_OVERTIME_PAY"] = "shift.single_overtime_pay";
    NativePath["SHIFT_DOUBLE_OVERTIME_HOURS"] = "shift.double_overtime_hours";
    NativePath["SHIFT_DOUBLE_OVERTIME_PAY"] = "shift.double_overtime_pay";
    NativePath["SHIFT_TOTAL_PAY"] = "shift.total_pay";
    NativePath["SHIFT_BILL_RATE"] = "shift.bill_rate";
    NativePath["SHIFT_TEMPLATE_ID"] = "shift.from_template_id";
    NativePath["SHIFT_PAYROLL_STATUS"] = "shift.payroll_status";
    NativePath["SHIFT_GROUP_ID"] = "shift.group_id";
    NativePath["SHIFT_TOTAL_BILL"] = "shift.total_bill";
    // automation
    NativePath["AUTOMATION_NAME"] = "automation.name";
    NativePath["AUTOMATION_IS_ACTIVE"] = "automation.is_active";
    // location
    NativePath["LOCATION_NAME"] = "location.name";
    NativePath["LOCATION_ADDRESS"] = "location.address";
    NativePath["LOCATION_GEOFENCE"] = "location.geofence";
    NativePath["LOCATION_BREAK_POLICY_ID"] = "location.break_policy_id";
    NativePath["LOCATION_ARCHIVED"] = "location.archived";
    // role
    NativePath["ROLE_NAME"] = "roles.name";
    NativePath["ROLE_IS_ACTIVE"] = "roles.is_active";
    // pay period
    NativePath["PAY_PERIOD_START_DATE"] = "pay_periods.start_date";
    NativePath["PAY_PERIOD_END_DATE"] = "pay_periods.end_date";
    NativePath["PAY_PERIOD_APPROVED"] = "pay_periods.approved";
    NativePath["PAY_PERIOD_TYPE"] = "pay_periods.type";
    // contact
    NativePath["CONTACT_FIRST_NAME"] = "contacts.first_name";
    NativePath["CONTACT_LAST_NAME"] = "contacts.last_name";
    NativePath["CONTACT_EMAIL"] = "contacts.email";
    NativePath["CONTACT_PHONE"] = "contacts.phone";
    // document
    NativePath["DOCUMENT_FILE"] = "document.file";
    NativePath["DOCUMENT_STATUS"] = "document.status";
    NativePath["DOCUMENT_IS_REQUIRED"] = "document.is_required";
    NativePath["DOCUMENT_ROLE_IDS"] = "document.role_rids";
    NativePath["DOCUMENT_USER_RID"] = "document.user_rid";
    NativePath["DOCUMENT_EXPIRY_DATE"] = "document.expiry_date";
    // task
    NativePath["TASK_TEMPLATE_ID"] = "task.template_id";
    NativePath["TASK_DESCRIPTION"] = "task.description";
    NativePath["TASK_USER_RID"] = "task.user_rid";
    NativePath["TASK_STATUS"] = "task.status";
    NativePath["TASK_VISIBILITY"] = "task.visibility";
    NativePath["TASK_DUE_DATE"] = "task.due_date";
    NativePath["TASK_NOTES"] = "task.notes";
    NativePath["TASK_COMPLETED_AT"] = "task.completed_at";
    NativePath["TASK_GROUP_ID"] = "task.group_id";
    NativePath["TASK_PLACEMENT_RID"] = "task.placement_rid";
    NativePath["TASK_SHIFT_RID"] = "task.shift_rid";
    NativePath["TASK_DISMISSED"] = "task.dismissed";
    NativePath["TASK_RULE_ID"] = "task.rule_id";
    // shift group
    NativePath["SHIFT_GROUP_NAME"] = "shift_group.name";
    NativePath["SHIFT_GROUP_START_AT"] = "shift_group.start_at";
    NativePath["SHIFT_GROUP_END_AT"] = "shift_group.end_at";
    NativePath["SHIFT_GROUP_LOCATIONS"] = "shift_group.location";
    NativePath["SHIFT_GROUP_ROLES"] = "shift_group.role";
    NativePath["SHIFT_GROUP_ASSIGNEES"] = "shift_group.assignees";
    NativePath["SHIFT_GROUP_STATUS"] = "shift_group.status";
    // placement
    NativePath["PLACEMENT_JOB_RID"] = "placement.job_rid";
    NativePath["PLACEMENT_NAME"] = "placement.name";
    NativePath["PLACEMENT_DESCRIPTION"] = "placement.description";
    NativePath["PLACEMENT_START_DATE"] = "placement.start_date";
    NativePath["PLACEMENT_END_DATE"] = "placement.end_date";
    NativePath["PLACEMENT_ROLE_RIDS"] = "placement.role_rids";
    NativePath["PLACEMENT_LOCATION_RID"] = "placement.location_rid";
    NativePath["PLACEMENT_HOURLY_RATE"] = "placement.hourly_rate";
    NativePath["PLACEMENT_HOURS_PER_WEEK"] = "placement.hours_per_week";
    NativePath["PLACEMENT_YEARS_OF_EXPERIENCE"] = "placement.years_of_experience";
    NativePath["PLACEMENT_STATUS"] = "placement.status";
    NativePath["PLACEMENT_STAGE"] = "placement.stage";
    NativePath["PLACEMENT_USER_RID"] = "placement.user_rid";
    NativePath["PLACEMENT_SUPERVISOR_RID"] = "placement.supervisor_rid";
    NativePath["PLACEMENT_NOTES"] = "placement.notes";
    NativePath["PLACEMENT_PUBLISHED"] = "placement.published";
    // job
    NativePath["JOB_NAME"] = "job.name";
    NativePath["JOB_DESCRIPTION"] = "job.description";
    NativePath["JOB_START_DATE"] = "job.start_date";
    NativePath["JOB_END_DATE"] = "job.end_date";
    NativePath["JOB_HOURLY_RATE"] = "job.hourly_rate";
    NativePath["JOB_HOURS_PER_WEEK"] = "job.hours_per_week";
    NativePath["JOB_YEARS_OF_EXPERIENCE"] = "job.years_of_experience";
    NativePath["JOB_LOCATION_ID"] = "job.location_id";
    NativePath["JOB_ROLE_IDS"] = "job.role_ids";
    NativePath["JOB_STATUS"] = "job.status";
    NativePath["JOB_PUBLISHED"] = "job.published";
})(NativePath || (NativePath = {}));
export var TitleSchemaNativePathSet = new Set([
    NativePath.LOCATION_NAME,
    NativePath.ROLE_NAME,
    NativePath.SHIFT_GROUP_NAME,
]);
export var ShiftGeneratedSchemaSet = new Set([
    NativePath.SHIFT_HOURS_WORKED,
    NativePath.SHIFT_HOURS_SCHEDULED,
    NativePath.SHIFT_REGULAR_HOURS,
    NativePath.SHIFT_REGULAR_PAY,
    NativePath.SHIFT_SINGLE_OVERTIME_HOURS,
    NativePath.SHIFT_SINGLE_OVERTIME_PAY,
    NativePath.SHIFT_DOUBLE_OVERTIME_HOURS,
    NativePath.SHIFT_DOUBLE_OVERTIME_PAY,
    NativePath.SHIFT_TOTAL_PAY,
    NativePath.SHIFT_TOTAL_BILL,
]);
export var ShiftDurationGeneratedSchemaSet = new Set([
    NativePath.SHIFT_HOURS_WORKED,
    NativePath.SHIFT_HOURS_SCHEDULED,
    NativePath.SHIFT_REGULAR_HOURS,
    NativePath.SHIFT_SINGLE_OVERTIME_HOURS,
    NativePath.SHIFT_DOUBLE_OVERTIME_HOURS,
]);
export var NonSchemaNativePath;
(function (NonSchemaNativePath) {
    NonSchemaNativePath["BREAK_SHIFT_RID"] = "break.shift_rid";
    NonSchemaNativePath["SHIFT_GEO_TRACKING"] = "shift.geo_tracking";
    NonSchemaNativePath["SHIFT_ASSIGNEE_DATA"] = "shift.assignee_data";
    NonSchemaNativePath["SHIFT_TEMPLATE_ID"] = "shift.template_id";
    NonSchemaNativePath["USER_AUTH_USER_ID"] = "user.auth_user_id";
    NonSchemaNativePath["USER_AVATAR"] = "user.avatar_url";
    NonSchemaNativePath["SHIFT_FACE_PIC"] = "shift.face_pic";
    NonSchemaNativePath["SHIFT_ACCOUNT_WAGE_ID"] = "shift.linked_acc_wage_id";
    NonSchemaNativePath["SHIFT_INDIV_WAGE_ID"] = "linked_indiv_wage_id";
    NonSchemaNativePath["SHIFT_RECURRING_RULE_ID"] = "shift.recurring_rule_id";
    NonSchemaNativePath["SHIFT_OPEN_COUNT"] = "shift.open_count";
    NonSchemaNativePath["SHIFT_LINKED_TEMPLATE"] = "shift.from_template_id";
    NonSchemaNativePath["SHIFT_TIME_ZONE_ID"] = "shift.time_zone_id";
    NonSchemaNativePath["SHIFT_IS_GROUP"] = "shift.is_group";
    NonSchemaNativePath["TASK_COMPLETION_DETAIL"] = "task.completion_detail";
})(NonSchemaNativePath || (NonSchemaNativePath = {}));
// type guard
export var isStandardNativeType = function (type) {
    return (type === 'user' ||
        type === 'shift' ||
        type === 'break' ||
        type === 'timeoff' ||
        type === 'automation' ||
        type === 'location' ||
        type === 'role' ||
        type === 'geofences' ||
        type === 'generated' ||
        type === 'contact' ||
        type === 'shift_group' ||
        type === 'job' ||
        type === 'placement');
};
export var selectionStandardTypes = new Set([
    'user',
    'location',
    'role',
    'break',
    'break_rule',
    'timeoff',
    'timeoff_type',
    'job',
    'placement',
    'shift',
    'overtime',
    'access_group',
    'geofences',
    'selection',
    'native_template_id',
    'native_holiday_policy_id',
    'native_group_id',
]);
// native text cell render with
export var NativeTextInputSet = new Set([
    ValueDataType.TEXT,
    ValueDataType.NAME,
    ValueDataType.EMAIL,
    ValueDataType.URL,
    ValueDataType.CURRENCY,
    ValueDataType.NUMBER,
    ValueDataType.PHONE,
    ValueDataType.GENERATED,
    ValueDataType.NATIVE_USER_PIN,
    ValueDataType.NATIVE_DURATION,
]);
export var NativeNameInputSet = new Set([
    NativePath.LOCATION_NAME,
    NativePath.ROLE_NAME,
    NativePath.USER_FIRST_NAME,
    NativePath.USER_LAST_NAME,
]);
export var NativeShiftRequestOptionSet = new Set([NativePath.SHIFT_WAGE, NativePath.SHIFT_BONUS]);
// Selection type set
export var SelectionTypeSet = new Set([
    ValueDataType.PLAIN_SELECTION,
    ValueDataType.NATIVE_USER_ID,
    ValueDataType.NATIVE_USER_IDS,
    ValueDataType.NATIVE_TYPE_ID,
    ValueDataType.NATIVE_LOCATION_ID,
    ValueDataType.NATIVE_LOCATION_IDS,
    ValueDataType.NATIVE_ACCESS_GROUP_IDS,
    ValueDataType.NATIVE_ROLE_IDS,
    ValueDataType.NATIVE_JOB_RID,
    ValueDataType.NATIVE_PLACEMENT_RID,
    ValueDataType.NATIVE_SHIFT_RID,
    ValueDataType.NATIVE_TIMEOFF_POLICY_ID,
    ValueDataType.NATIVE_OVERTIME_POLICY_ID,
    ValueDataType.NATIVE_HOLIDAY_POLICY_ID,
    ValueDataType.NATIVE_BREAK_POLICY_ID,
    ValueDataType.NATIVE_BREAK_RULE_ID,
    ValueDataType.NATIVE_GEOFENCE_ID,
    ValueDataType.NATIVE_GROUP_ID,
    ValueDataType.NATIVE_GENERATED,
    ValueDataType.GENERATED,
    ValueDataType.CUSTOM,
    ValueDataType.SHIFT,
    ValueDataType.USER,
    ValueDataType.LOCATION,
    ValueDataType.ROLE,
    ValueDataType.PAY_PERIOD,
    ValueDataType.NATIVE_SELECTION,
    ValueDataType.NATIVE_BOOL,
]);
// multi selection set
export var NativeMultiSet = new Set([
    NativePath.SHIFT_ROLE_IDS,
    NativePath.USER_ROLES,
    NativePath.USER_LOCATIONS,
    NativePath.JOB_ROLE_IDS,
    NativePath.PLACEMENT_ROLE_RIDS,
]);
// to hide in detail modal
export var NativeHiddenSet = new Set([
    NativePath.SHIFT_CLOCK_IN,
    NativePath.SHIFT_CLOCK_OUT,
    NativePath.SHIFT_START_AT,
    NativePath.SHIFT_END_AT,
    NativePath.SHIFT_STATUS,
]);
// to hide in quick create
export var QuickCreateNativeHiddenSet = new Set([
    NativePath.SHIFT_CLOCK_OUT,
    NativePath.SHIFT_END_AT,
    NativePath.SHIFT_STATUS,
]);
// Always show in quick create
export var QuickCreateNativePathDefaultSet = new Set([
    NativePath.SHIFT_START_AT,
    NativePath.SHIFT_END_AT,
    NativePath.SHIFT_CLOCK_IN,
    NativePath.SHIFT_CLOCK_OUT,
    NativePath.SHIFT_USER_ID,
    NativePath.SHIFT_LOCATION_ID,
    NativePath.SHIFT_ROLE_IDS,
    NativePath.USER_FIRST_NAME,
    NativePath.USER_LAST_NAME,
    NativePath.USER_EMAIL,
    NativePath.USER_ROLES,
    NativePath.USER_LOCATIONS,
]);
// schedule color coding set
export var NativeScheduleColorSet = new Set([
    NativePath.SHIFT_LOCATION_ID,
    NativePath.SHIFT_ROLE_IDS,
]);
export var NativeGeneratedSet = new Set([
    NativePath.SHIFT_STATUS,
    NativePath.SHIFT_HOURS_WORKED,
    NativePath.SHIFT_HOURS_SCHEDULED,
    NativePath.SHIFT_REGULAR_HOURS,
    NativePath.SHIFT_REGULAR_PAY,
    NativePath.SHIFT_SINGLE_OVERTIME_HOURS,
    NativePath.SHIFT_SINGLE_OVERTIME_PAY,
    NativePath.SHIFT_DOUBLE_OVERTIME_HOURS,
    NativePath.SHIFT_DOUBLE_OVERTIME_PAY,
    NativePath.SHIFT_TOTAL_PAY,
    NativePath.SHIFT_GROUP_STATUS,
    NativePath.SHIFT_TOTAL_BILL,
]);
// integration use
export var ZIRA_TITLE = 'TeamBridge';
export var ObjectPermission;
(function (ObjectPermission) {
    ObjectPermission["READ"] = "read";
    ObjectPermission["WRITE"] = "write";
})(ObjectPermission || (ObjectPermission = {}));
export var FilterPermission;
(function (FilterPermission) {
    FilterPermission["FILTER_VALUE"] = "filter_value";
})(FilterPermission || (FilterPermission = {}));
export var ActionType;
(function (ActionType) {
    ActionType["ADD"] = "add";
    ActionType["REMOVE"] = "remove";
    ActionType["SWAP"] = "swap";
})(ActionType || (ActionType = {}));
export var AccountEditingPermission;
(function (AccountEditingPermission) {
    AccountEditingPermission["READ_WEB"] = "Read Web";
    AccountEditingPermission["CREATE_CATEGORY"] = "Create Category";
    AccountEditingPermission["CREATE_PAGE"] = "Create Page";
    AccountEditingPermission["CREATE_PAGE_BLOCK"] = "Create Page Block";
    AccountEditingPermission["MOVE_PAGE"] = "Move Page";
    AccountEditingPermission["CREATE_COLLECTION"] = "Create Collection";
    AccountEditingPermission["CREATE_SCHEMA"] = "Create/Update Schema";
    AccountEditingPermission["WRITE_ACCOUNT"] = "Write Account";
    AccountEditingPermission["AUTOMATICALLY_APPLY_FILTERS"] = "Automatically Update Filters";
})(AccountEditingPermission || (AccountEditingPermission = {}));
export var OwnerEditableAccountPermission = [
    'AUTOMATICALLY_APPLY_FILTERS',
];
export var AccountEditingPermissionDescription;
(function (AccountEditingPermissionDescription) {
    AccountEditingPermissionDescription["READ_WEB"] = "Access TeamBridge web portal";
    AccountEditingPermissionDescription["CREATE_CATEGORY"] = "Create Category";
    AccountEditingPermissionDescription["CREATE_PAGE"] = "Create a page";
    AccountEditingPermissionDescription["CREATE_PAGE_BLOCK"] = "Create a block within a page";
    AccountEditingPermissionDescription["MOVE_PAGE"] = "Change the order among pages within the account";
    AccountEditingPermissionDescription["CREATE_COLLECTION"] = "Create a collection";
    AccountEditingPermissionDescription["CREATE_SCHEMA"] = "Create/Update a schema (column) within a collection";
    AccountEditingPermissionDescription["WRITE_ACCOUNT"] = "Edit basic information about your TeamBridge account";
    AccountEditingPermissionDescription["AUTOMATICALLY_APPLY_FILTERS"] = "When turned on, any change to filters on a list or calendar view will show for all users viewing the same page. When off the filter change will only occur for the current user and will be reset when refreshing the page.";
})(AccountEditingPermissionDescription || (AccountEditingPermissionDescription = {}));
export var AccountUserManagementPermission;
(function (AccountUserManagementPermission) {
    AccountUserManagementPermission["READ_WAGE"] = "Read Wage";
    AccountUserManagementPermission["WRITE_WAGE"] = "Write Wage";
    AccountUserManagementPermission["READ_PERMISSION"] = "Read Permission";
    AccountUserManagementPermission["WRITE_PERMISSION"] = "Write Permission";
    AccountUserManagementPermission["READ_SHIFT"] = "Read Shift";
    AccountUserManagementPermission["WRITE_SHIFT"] = "Write Shift";
    AccountUserManagementPermission["READ_SHIFT_REQUEST"] = "Read Shift Request";
    AccountUserManagementPermission["WRITE_SHIFT_REQUEST"] = "Write Shift Request";
})(AccountUserManagementPermission || (AccountUserManagementPermission = {}));
export var AccountUserManagementPermissionDescription;
(function (AccountUserManagementPermissionDescription) {
    AccountUserManagementPermissionDescription["READ_WAGE"] = "View wage information within account";
    AccountUserManagementPermissionDescription["WRITE_WAGE"] = "Create and update wage information within account";
    AccountUserManagementPermissionDescription["READ_PERMISSION"] = "View user permissions within account";
    AccountUserManagementPermissionDescription["WRITE_PERMISSION"] = "Create and update user permissions within account, including creating access groups, assigning new permissions";
    AccountUserManagementPermissionDescription["READ_SHIFT"] = "View shift information within account";
    AccountUserManagementPermissionDescription["WRITE_SHIFT"] = "Create and update shifts within account";
    AccountUserManagementPermissionDescription["READ_SHIFT_REQUEST"] = "View shift requests";
    AccountUserManagementPermissionDescription["WRITE_SHIFT_REQUEST"] = "Create and update shift requests";
})(AccountUserManagementPermissionDescription || (AccountUserManagementPermissionDescription = {}));
export var AccountBillingPermission;
(function (AccountBillingPermission) {
    AccountBillingPermission["READ_AUTOMATION"] = "Read Automation";
    AccountBillingPermission["WRITE_AUTOMATION"] = "Write Automation";
    AccountBillingPermission["CREATE_REPORT"] = "Create Report";
})(AccountBillingPermission || (AccountBillingPermission = {}));
export var AccountBillingPermissionDescription;
(function (AccountBillingPermissionDescription) {
    AccountBillingPermissionDescription["READ_AUTOMATION"] = "View automation settings";
    AccountBillingPermissionDescription["WRITE_AUTOMATION"] = "Create and change automation settings";
    AccountBillingPermissionDescription["CREATE_REPORT"] = "Create and update reports";
})(AccountBillingPermissionDescription || (AccountBillingPermissionDescription = {}));
export var AccountMobileClockInPermission;
(function (AccountMobileClockInPermission) {
    AccountMobileClockInPermission["ALLOW_MOBILE_CLOCK_IN"] = "Allow Mobile Clock In";
    AccountMobileClockInPermission["ALLOW_UNSCHEDULED_SHIFTS"] = "Allow Unscheduled Shifts";
    AccountMobileClockInPermission["EARLY_CLOCK_IN_RESTRICTION"] = "Early Clock In Restriction";
    AccountMobileClockInPermission["TRACK_EMPLOYEE_LOCATION"] = "Track Employee Location (During Shifts)";
    AccountMobileClockInPermission["GEOFENCE_SHIFTS"] = "Geofence Shifts";
})(AccountMobileClockInPermission || (AccountMobileClockInPermission = {}));
export var AccountMobileClockOutPermission;
(function (AccountMobileClockOutPermission) {
    AccountMobileClockOutPermission["CLOCK_OUT_REQUIRED_SCHEMAS"] = "Required Fields for Clock Out";
    AccountMobileClockOutPermission["CLOCK_OUT_OPTIONAL_SCHEMAS"] = "Optional Fields for Clock Out";
})(AccountMobileClockOutPermission || (AccountMobileClockOutPermission = {}));
export var AccountMobileFeedPermission;
(function (AccountMobileFeedPermission) {
    AccountMobileFeedPermission["ALLOW_COMMENTS"] = "Allow Comments";
    AccountMobileFeedPermission["ALLOW_POST_CREATION"] = "Allow Post Creation";
})(AccountMobileFeedPermission || (AccountMobileFeedPermission = {}));
export var AccountMobileSchedulePermission;
(function (AccountMobileSchedulePermission) {
    AccountMobileSchedulePermission["ALLOW_RELEASE_REQUETS"] = "Allow Shift Release";
    AccountMobileSchedulePermission["ALLOW_SHIFT_CLAIM"] = "Allow Shift Claim";
    AccountMobileSchedulePermission["ALLOW_WITHDRAW_SHIFT_CLAIM"] = "Allow Withdraw Shift Claim";
    AccountMobileSchedulePermission["VIEW_OTHER_EMPLOYEES_SHIFTS"] = "View Other Employees Shifts";
    AccountMobileSchedulePermission["SHIFT_PREVIEW_PAY_RATE"] = "Preview Pay Rate on Shifts";
    AccountMobileSchedulePermission["SHIFT_CLAIM_EXPIRY_DATE_CHECK"] = "Check Expiry Date on Shift Claim";
})(AccountMobileSchedulePermission || (AccountMobileSchedulePermission = {}));
export var AllMobilePermissions = __assign(__assign(__assign(__assign({}, AccountMobileClockInPermission), AccountMobileClockOutPermission), AccountMobileFeedPermission), AccountMobileSchedulePermission);
export var ObjType;
(function (ObjType) {
    ObjType["PAGE"] = "page";
    ObjType["COLLECTION"] = "collection";
    ObjType["SCHEMA"] = "schema";
})(ObjType || (ObjType = {}));
export var NewsFeedSendtoType;
(function (NewsFeedSendtoType) {
    NewsFeedSendtoType["ALL"] = "ALL";
    NewsFeedSendtoType["USER"] = "USER";
    NewsFeedSendtoType["ROLE"] = "ROLE";
    NewsFeedSendtoType["LOCATION"] = "LOCATION";
})(NewsFeedSendtoType || (NewsFeedSendtoType = {}));
export var SubMenuContentIcon = (_a = {},
    _a['title'] = _jsx(StartOutlinedIcon, {}),
    _a['text'] = _jsx(TextOutlinedIcon, {}),
    _a['number'] = _jsx(NumberlinedIcon, {}),
    _a['break'] = _jsx(SingleSelectOutlinedIcon, {}),
    _a['break_rule'] = _jsx(SingleSelectOutlinedIcon, {}),
    _a['timeoff'] = _jsx(SingleSelectOutlinedIcon, {}),
    _a['overtime'] = _jsx(SingleSelectOutlinedIcon, {}),
    _a['timeoff_type'] = _jsx(SingleSelectOutlinedIcon, {}),
    _a['access_group'] = _jsx(SingleSelectOutlinedIcon, {}),
    _a['single'] = _jsx(SingleSelectOutlinedIcon, {}),
    _a['multi'] = _jsx(MultiSelectOutlinedIcon, {}),
    _a['file'] = _jsx(FileOutlinedIcon, {}),
    _a['signature'] = _jsx(SignatureOutlinedIcon, {}),
    _a['datetime'] = _jsx(DateOutlinedIcon, {}),
    _a['geofences'] = _jsx(LocationOutlinedIcon, {}),
    _a['currency'] = _jsx(CurrencyOutlineIcon, {}),
    _a['native'] = _jsx(LinkOutlinedIcon, {}),
    _a['user'] = _jsx(SingleSelectOutlinedIcon, {}),
    _a['role'] = _jsx(MultiSelectOutlinedIcon, {}),
    _a['location'] = _jsx(SingleSelectOutlinedIcon, {}),
    _a['custom'] = _jsx(LinkOutlinedIcon, {}),
    _a['linkToObj'] = _jsx(LinkOutlinedIcon, {}),
    _a['linkToSchema'] = _jsx(LinkOutlinedIcon, {}),
    _a['formula'] = _jsx(FormulaOutlinedIcon, {}),
    _a['metric_currency'] = _jsx(FormulaOutlinedIcon, {}),
    _a['metric_percentage'] = _jsx(FormulaOutlinedIcon, {}),
    _a['metric'] = _jsx(FormulaOutlinedIcon, {}),
    _a['phone'] = _jsx(PhoneOutlinedIcon, {}),
    _a['generated'] = _jsx(LockOutlined, { className: "LockOutlinedIcon text-sm leading-none text-gray-300" }),
    _a['selection'] = _jsx(SingleSelectOutlinedIcon, {}),
    _a['rollup'] = _jsx(RollupOutlineIcon, {}),
    _a['button'] = _jsx(ButtonSchemaOutlinedIcon, { className: "mt-[2px]" }),
    _a['generative'] = _jsx(SparkleOutlinedIcon, { className: "mt-[2px]" }),
    _a);
export var BreakType;
(function (BreakType) {
    BreakType["PAID"] = "PAID";
    BreakType["UNPAID"] = "UNPAID";
})(BreakType || (BreakType = {}));
export var TimeoffAccrualType;
(function (TimeoffAccrualType) {
    TimeoffAccrualType["HOURS"] = "HOURS_WORKED";
    TimeoffAccrualType["PAY"] = "PAY_PERIOD";
    TimeoffAccrualType["NULL"] = "NO_ACCRUAL";
})(TimeoffAccrualType || (TimeoffAccrualType = {}));
export var ScheduleGroupBy;
(function (ScheduleGroupBy) {
    ScheduleGroupBy["USER"] = "USER";
    ScheduleGroupBy["LOCATION"] = "LOCATION";
    ScheduleGroupBy["ROLE"] = "ROLE";
    ScheduleGroupBy["TEMPLATE"] = "TEMPLATE";
})(ScheduleGroupBy || (ScheduleGroupBy = {}));
export var CurrencyMap = (_b = {},
    _b['USD'] = '$',
    _b['EUR'] = '€',
    _b['CAD'] = 'CA$',
    _b['AUD'] = 'A$',
    _b['GBP'] = '£',
    _b['JPY'] = '¥',
    _b['INR'] = '₹',
    _b);
export var ToastMessage;
(function (ToastMessage) {
    ToastMessage["SAVE_SUCCESS"] = "Saved Successfully";
    ToastMessage["SAVE_FAIL"] = "Save Failed";
    ToastMessage["ADD_SUCCESS"] = "Added Successfully";
    ToastMessage["ADD_FAIL"] = "Add Failed";
    ToastMessage["UPDATE_SUCCESS"] = "Updated Successfully";
    ToastMessage["UPDATE_FAIL"] = "Update Failed";
})(ToastMessage || (ToastMessage = {}));
export var ReportCurrencyCol;
(function (ReportCurrencyCol) {
    ReportCurrencyCol["REGULAR_PAY"] = "regular_pay";
    ReportCurrencyCol["OVERTIME_PAY"] = "overtime_pay";
    ReportCurrencyCol["DOUBLE_OVERTIME_PAY"] = "double_overtime_pay";
    ReportCurrencyCol["TOTAL_PAY"] = "total_pay";
})(ReportCurrencyCol || (ReportCurrencyCol = {}));
export var ReportShowCurrencySignSet = new Set([
    ReportCurrencyCol.REGULAR_PAY,
    ReportCurrencyCol.OVERTIME_PAY,
    ReportCurrencyCol.DOUBLE_OVERTIME_PAY,
    ReportCurrencyCol.TOTAL_PAY,
]);
export var MetricBarShowCurrencySignSet = new Set([
    'regular_pay',
    'overtime_pay',
    'total_pay',
    'double_overtime_pay',
]);
export var NoShowOpenModalButtonSet = new Set(['No Assignment', 'No Location', 'No Role']);
export var ClauseToHideFilterValueSet = new Set([
    'last_seven_days',
    'last_thirty_days',
    'week_to_date',
    'month_to_date',
    'year_to_date',
    'last_week',
    'last_month',
    'last_year',
    'yesterday',
    'today',
    'tomorrow',
    'this_week',
    'this_month',
    'this_year',
    'next_week',
    'next_month',
    'next_year',
    'before_today',
    'after_today',
    'today_and_after',
    'last_pay_period',
    'current_pay_period',
    'is_empty',
    'is_not_empty',
    'current_user_is',
    'current_user_is_not',
]);
export var specialDefineEmptyClauseTypes = new Set(__spreadArray([
    'is_empty',
    'is_not_empty',
    'Is Empty',
    'Is Not Empty'
], Array.from(ClauseToHideFilterValueSet), true));
export var convertCurrentUserClause = function (clause, value) {
    if (value === 'current_user') {
        if (clause === 'option_contains') {
            return 'current_user_is';
        }
        if (clause === 'option_not_contains') {
            return 'current_user_is_not';
        }
    }
    if (clause === 'current_user_is') {
        return 'option_contains';
    }
    if (clause === 'current_user_is_not') {
        return 'option_not_contains';
    }
    return clause;
};
export var StartDayOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];
export var isNativeBooleanOrSpecialType = function (type) {
    return type === ValueDataType.NATIVE_BOOL || type === ValueDataType.NATIVE_SPECIAL;
};
export var isBooleanType = function (type) {
    return type === ValueDataType.NATIVE_BOOL || type === ValueDataType.BOOLEAN;
};
export var isShiftPayrollStatusSchema = function (nativePath) {
    return nativePath === NativePath.SHIFT_PAYROLL_STATUS;
};
export var isIgnoreUniversalListSchema = function (nativePath) {
    return nativePath === NativePath.TASK_TEMPLATE_ID || nativePath === NativePath.TASK_GROUP_ID;
};
export var isPlainSelection = function (type) {
    return type === ValueDataType.PLAIN_SELECTION;
};
export var allowInlineCreateStandardObj = function (type) {
    return type === ValueDataType.CUSTOM ||
        type === ValueDataType.NATIVE_LOCATION_IDS ||
        type === ValueDataType.NATIVE_ROLE_IDS ||
        type === ValueDataType.NATIVE_LOCATION_ID ||
        type === ValueDataType.LOCATION ||
        type === ValueDataType.ROLE ||
        type === ValueDataType.NATIVE_GROUP_ID;
};
export var allowCreateUserObj = function (type) {
    return type === ValueDataType.USER || type === ValueDataType.NATIVE_USER_ID;
};
export var StyleColorMap = {
    GREEN: '#E5F4EF',
    GREY: '#F4F4F7',
};
export var isColorNameStyle = function (type) {
    return type === 'GREEN' || type === 'GREY';
};
export var SupportedWageQualifierSet = new Set([
    NativePath.SHIFT_LOCATION_ID,
    NativePath.SHIFT_ROLE_IDS,
    NativePath.SHIFT_START_AT,
    NativePath.SHIFT_END_AT,
    NativePath.SHIFT_CLOCK_IN,
    NativePath.SHIFT_CLOCK_OUT,
]);
export var DeletableGeneratedSchemaSet = new Set([ValueDataType.FORMULA, ValueDataType.ROLLUP]);
export var MetricDateRanges = [
    {
        label: 'Last 7 Days',
        value: 'last_seven_days',
    },
    {
        label: 'Last 30 Days',
        value: 'last_thirty_days',
    },
    {
        label: 'Week to Date',
        value: 'week_to_date',
    },
    {
        label: 'Month to Date',
        value: 'month_to_date',
    },
    {
        label: 'Year to Date',
        value: 'year_to_date',
    },
    {
        label: 'Last Week',
        value: 'last_week',
    },
    {
        label: 'Last Month',
        value: 'last_month',
    },
    {
        label: 'Last Year',
        value: 'last_year',
    },
    {
        label: 'Yesterday',
        value: 'yesterday',
    },
    {
        label: 'Today',
        value: 'today',
    },
    {
        label: 'Tomorrow',
        value: 'tomorrow',
    },
    {
        label: 'This Week',
        value: 'this_week',
    },
    {
        label: 'This Month',
        value: 'this_month',
    },
    {
        label: 'This Year',
        value: 'this_year',
    },
    {
        label: 'Next Week',
        value: 'next_week',
    },
    {
        label: 'Next Month',
        value: 'next_month',
    },
    {
        label: 'Next Year',
        value: 'next_year',
    },
    {
        label: 'Before Today',
        value: 'before_today',
    },
    {
        label: 'After Today',
        value: 'after_today',
    },
    {
        label: 'Today and After',
        value: 'today_and_after',
    },
    {
        label: 'Last Pay Period',
        value: 'last_pay_period',
    },
    {
        label: 'Current Pay Period',
        value: 'current_pay_period',
    },
];
export var FormulaOperators = [
    {
        symbol: '+',
        value: '+',
        color: '#00A76F',
        backgroundColor: '#E5F4EF',
    },
    {
        symbol: '-',
        value: '-',
        color: '#B3342F',
        backgroundColor: '#FFEDEC',
    },
    {
        symbol: 'X',
        value: '*',
        color: '#0F58A0',
        backgroundColor: '#E8F2FC',
    },
    {
        symbol: '/',
        value: '/',
        color: '#B38632',
        backgroundColor: '#FFF9ED',
    },
    {
        symbol: '(',
        value: '(',
        color: '#000000',
        backgroundColor: '#F4F4F7',
    },
    {
        symbol: ')',
        value: ')',
        color: '#000000',
        backgroundColor: '#F4F4F7',
    },
];
export var booleanDifferentialQualifierOptions = [
    {
        id: 'true',
        text: 'Yes',
        color: '#E5F4EF',
    },
    {
        id: 'false',
        text: 'No',
        color: '#FFEDEC',
    },
];
export var overtimeDifferentialQualifierOptions = [
    {
        id: 'REGULAR',
        text: 'Regular',
        color: '#E5F4EF',
    },
    {
        id: 'OVERTIME',
        text: 'Overtime',
        color: '#FFF9ED',
    },
    {
        id: 'DOUBLE_OVERTIME',
        text: 'Double Overtime',
        color: '#FFEDEC',
    },
];
export var dayOfWeekOptionsMap = (_c = {},
    _c['MONDAY'] = {
        key: 'MONDAY',
        displayName: 'Mon',
        color: '#E5F4EF',
    },
    _c['TUESDAY'] = {
        key: 'TUESDAY',
        displayName: 'Tue',
        color: '#FFF9ED',
    },
    _c['WEDNESDAY'] = {
        key: 'WEDNESDAY',
        displayName: 'Wed',
        color: '#FFF1EB',
    },
    _c['THURSDAY'] = {
        key: 'THURSDAY',
        displayName: 'Thu',
        color: '#FFEDEC',
    },
    _c['FRIDAY'] = {
        key: 'FRIDAY',
        displayName: 'Fri',
        color: '#F3F1FB',
    },
    _c['SATURDAY'] = { key: 'SATURDAY', displayName: 'Sat', color: '#EAEDFC' },
    _c['SUNDAY'] = {
        key: 'SUNDAY',
        displayName: 'Sun',
        color: '#E8F2FC',
    },
    _c);
export var DifferentialSelectorType;
(function (DifferentialSelectorType) {
    DifferentialSelectorType["DAY_OF_WEEK"] = "dayOfWeek";
    DifferentialSelectorType["HOLIDAY"] = "holiday";
    DifferentialSelectorType["OVERTIME"] = "overtime";
    DifferentialSelectorType["ROLE"] = "role";
})(DifferentialSelectorType || (DifferentialSelectorType = {}));
export var CollectionTableType;
(function (CollectionTableType) {
    CollectionTableType["TABLE"] = "TABLE";
    CollectionTableType["DETAIL_MODAL"] = "DETAIL_MODAL";
    CollectionTableType["NEW_DETAIL_MODAL"] = "NEW_DETAIL_MODAL";
    CollectionTableType["QUICK_CREATE_MODAL"] = "QUICK_CREATE_MODAL";
    CollectionTableType["SHIFT_TEMPLATE"] = "SHIFT_TEMPLATE";
    CollectionTableType["TASK_TEMPLATE_MODAL"] = "TASK_TEMPLATE_MODAL";
})(CollectionTableType || (CollectionTableType = {}));
export var DefaultAttestationNote = 'I hereby acknowledge that the shift times above are to the best of my knowledge true. I also confirm that there have been no violations during this pay period that I wish to report.';
export var defaultNewCustomCollectionIdentifier = 'newCustomCollectionCard';
export var newCustomCollectionCard = {
    identifier: 'newCustomCollectionCard',
    templateName: 'New Data Source',
    description: 'Create a new blank data source.',
};
export var overtimeDayOfWeekOptionsMap = (_d = {},
    _d[DayOfWeek.MONDAY] = 'Mon',
    _d[DayOfWeek.TUESDAY] = 'Tue',
    _d[DayOfWeek.WEDNESDAY] = 'Wed',
    _d[DayOfWeek.THURSDAY] = 'Thu',
    _d[DayOfWeek.FRIDAY] = 'Fri',
    _d[DayOfWeek.SATURDAY] = 'Sat',
    _d[DayOfWeek.SUNDAY] = 'Sun',
    _d);
export var BespokeReverseCollectionComponent;
(function (BespokeReverseCollectionComponent) {
    BespokeReverseCollectionComponent["USER_PAY_RATE"] = "USER_PAY_RATE";
    BespokeReverseCollectionComponent["SHIFT_TRACKING_MAP"] = "SHIFT_TRACKING_MAP";
    BespokeReverseCollectionComponent["SHIFT_PROJECT_TRACKING"] = "SHIFT_PROJECT_TRACKING";
    BespokeReverseCollectionComponent["SHIFT_REQUESTS"] = "SHIFT_REQUESTS";
    BespokeReverseCollectionComponent["RELEASE_REQUESTS"] = "RELEASE_REQUESTS";
    BespokeReverseCollectionComponent["LOCATION_PAY_RATE"] = "LOCATION_PAY_RATE";
    BespokeReverseCollectionComponent["USER_SCHEDULE"] = "USER_SCHEDULE";
    BespokeReverseCollectionComponent["LOCATION_SCHEDULE"] = "LOCATION_SCHEDULE";
    BespokeReverseCollectionComponent["ROLE_SCHEDULE"] = "ROLE_SCHEDULE";
})(BespokeReverseCollectionComponent || (BespokeReverseCollectionComponent = {}));
export var ScheduleReverseCollectionComponentSet = new Set([
    BespokeReverseCollectionComponent.USER_SCHEDULE,
    BespokeReverseCollectionComponent.LOCATION_SCHEDULE,
    BespokeReverseCollectionComponent.ROLE_SCHEDULE,
]);
export var RecordMetricName;
(function (RecordMetricName) {
    RecordMetricName["FIRST_LOG_IN"] = "FIRST_LOG_IN";
    RecordMetricName["RECORD_UPDATED_AT"] = "RECORD_UPDATED_AT";
    RecordMetricName["RECORD_CREATED_AT"] = "RECORD_CREATED_AT";
    RecordMetricName["LAST_SHIFT_REQUEST"] = "LAST_SHIFT_REQUEST";
    RecordMetricName["LAST_SCHEDULE_VIEW"] = "LAST_SCHEDULE_VIEW";
    RecordMetricName["LAST_SHIFT_ON_CALENDAR"] = "LAST_SHIFT_ON_CALENDAR";
    RecordMetricName["DOCUMENT_UPLOADED_AT"] = "DOCUMENT_UPLOADED_AT";
    RecordMetricName["DOCUMENT_FIRST_REQUIRED_EXPIRATION_DATE"] = "DOCUMENT_FIRST_REQUIRED_EXPIRATION_DATE";
    RecordMetricName["SHIFT_CLIENT_COUNT"] = "SHIFT_CLIENT_COUNT";
    RecordMetricName["USER_POINT_COUNT"] = "USER_POINT_COUNT";
    RecordMetricName["LOCATION_ASSIGNED_SHIFT_COUNT"] = "LOCATION_ASSIGNED_SHIFT_COUNT";
    RecordMetricName["LOCATION_TOTAL_SHIFT_COUNT"] = "LOCATION_TOTAL_SHIFT_COUNT";
    RecordMetricName["LOCATION_FULFILLED_SHIFT_RATIO"] = "LOCATION_FULFILLED_SHIFT_RATIO";
    RecordMetricName["SHIFT_GROUP_ASSIGNED_COUNT"] = "SHIFT_GROUP_ASSIGNED_COUNT";
    RecordMetricName["SHIFT_GROUP_TOTAL_COUNT"] = "SHIFT_GROUP_TOTAL_COUNT";
    RecordMetricName["SHIFT_FULFILLED_RATIO"] = "SHIFT_FULFILLED_RATIO";
    RecordMetricName["JOB_DURATION_MONTHS"] = "JOB_DURATION_MONTHS";
    RecordMetricName["JOB_WEEKLY_PAY_AMOUNT"] = "JOB_WEEKLY_PAY_AMOUNT";
})(RecordMetricName || (RecordMetricName = {}));
export var RecordMetricType;
(function (RecordMetricType) {
    RecordMetricType["DATETIME_METRIC"] = "DATETIME_METRIC";
    RecordMetricType["NUMBER_METRIC"] = "NUMBER_METRIC";
})(RecordMetricType || (RecordMetricType = {}));
export var StripeBillingStatus;
(function (StripeBillingStatus) {
    // not show banner
    StripeBillingStatus["ACTIVE"] = "ACTIVE";
    StripeBillingStatus["STRIPE_NOT_CONNECTED"] = "STRIPE_NOT_CONNECTED";
    StripeBillingStatus["NO_SUBSCRIPTION"] = "NO_SUBSCRIPTION";
    // show banner
    StripeBillingStatus["PAST_DUE"] = "PAST_DUE";
    StripeBillingStatus["NO_PAYMENT"] = "NO_PAYMENT";
    StripeBillingStatus["UNKNOWN"] = "UNKNOWN";
})(StripeBillingStatus || (StripeBillingStatus = {}));
export var ExportItemFileType;
(function (ExportItemFileType) {
    ExportItemFileType["CSV"] = "csv";
    ExportItemFileType["PDF"] = "pdf";
    ExportItemFileType["ZIP"] = "zip";
})(ExportItemFileType || (ExportItemFileType = {}));
export var ExportFileItemStatus;
(function (ExportFileItemStatus) {
    ExportFileItemStatus["FAILED"] = "failed";
    ExportFileItemStatus["SUCCEED"] = "succeed";
    ExportFileItemStatus["RUNNING"] = "running";
    ExportFileItemStatus["REQUESTED"] = "requested";
})(ExportFileItemStatus || (ExportFileItemStatus = {}));
export var fileItemStatusMap = (_e = {},
    _e[ExportFileItemStatus.SUCCEED] = {
        btnText: 'Download',
        color: ' #E9E9EC',
    },
    _e[ExportFileItemStatus.RUNNING] = {
        btnText: 'Pending',
        color: '#FFECC8',
    },
    _e[ExportFileItemStatus.FAILED] = {
        btnText: 'Failed',
        color: '#FFC9C7',
    },
    _e[ExportFileItemStatus.REQUESTED] = {
        btnText: 'Pending',
        color: '#FFECC8',
    },
    _e);
export var exportFileItemIcon = (_f = {},
    _f[ExportItemFileType.CSV] = _jsx(CsvExportFileIcon, {}),
    _f[ExportItemFileType.PDF] = _jsx(PdfExportFileIcon, {}),
    _f[ExportItemFileType.ZIP] = _jsx(ZipExportFileIcon, {}),
    _f);
