var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useWindowSize } from 'src/hooks/component';
import { naviTreeNodeKeyPathsSelector } from 'src/state';
import { pageTemplateModalState } from 'src/state/atoms/pageTemplateModalState';
import { useNaviTreeComponent } from 'src/hooks/component/useNaviTree';
import './PageTemplateModal.scss';
import { usePageApi } from 'src/hooks/api';
import './PageTemplateModal.scss';
import PageTemplateCard from './PageTemplateCard';
var PageTemplateModal = function () {
    var _a, _b;
    var navigate = useNavigate();
    var pageTemplateModal = useRecoilValue(pageTemplateModalState);
    var windowWidth = useWindowSize().windowWidth;
    var createPageInPageGroup = useNaviTreeComponent().createPageInPageGroup;
    var _c = usePageApi(), useGetAccountPageTemplateQuery = _c.useGetAccountPageTemplateQuery, useGetAccountBlockCategories = _c.useGetAccountBlockCategories;
    var _d = useGetAccountPageTemplateQuery({
        options: {
            enabled: pageTemplateModal.isVisible,
        },
    }).data, templateCategories = _d === void 0 ? [] : _d;
    var blockCategories = useGetAccountBlockCategories({
        options: {
            enabled: pageTemplateModal.isVisible,
        },
    }).data;
    var hasTimeOffRequestsAccess = blockCategories
        ? (_b = (_a = blockCategories.blockCategoryMap['Widgets']) === null || _a === void 0 ? void 0 : _a.find(function (widget) { return widget.collectionType === 'timeoff'; })) === null || _b === void 0 ? void 0 : _b.hasAccess
        : false;
    var onCreatePage = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (templateIdentifier) { return __awaiter(void 0, void 0, void 0, function () {
            var modalState, nodeKeyPaths, newPageId, tempSelectedKeys;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalState = snapshot.getLoadable(pageTemplateModalState).valueMaybe();
                        if (!modalState)
                            return [2 /*return*/];
                        nodeKeyPaths = snapshot
                            .getLoadable(naviTreeNodeKeyPathsSelector(modalState.selectedTreeItemId))
                            .valueMaybe();
                        if (!nodeKeyPaths)
                            return [2 /*return*/];
                        return [4 /*yield*/, createPageInPageGroup(nodeKeyPaths[0], templateIdentifier)];
                    case 1:
                        newPageId = _a.sent();
                        if (newPageId) {
                            tempSelectedKeys = [];
                            tempSelectedKeys[0] = nodeKeyPaths[0];
                            tempSelectedKeys[1] = newPageId;
                            navigate("/home/page/".concat(tempSelectedKeys[0], "/").concat(newPageId));
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var onClickTemplate = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (template) {
            if (template.identifier) {
                set(pageTemplateModalState, {
                    isVisible: false,
                    selectedTreeItemId: '',
                });
                onCreatePage(template.identifier);
            }
        };
    }, [onCreatePage]);
    var scrollParentToChild = useCallback(function (type) {
        var parent = document.getElementById('newBlockModalContent');
        if (!parent)
            return;
        var parentRect = parent.getBoundingClientRect();
        var child = document.getElementById(type);
        var childRect = child === null || child === void 0 ? void 0 : child.getBoundingClientRect();
        if (childRect) {
            var scrollTop = childRect.top - parentRect.top;
            parent.scrollTop += scrollTop - 20;
        }
    }, []);
    var onCancel = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function () {
            set(pageTemplateModalState, {
                isVisible: false,
                selectedTreeItemId: '',
            });
        };
    }, []);
    return (_jsxs(Modal, { className: "newBlockModal", open: pageTemplateModal.isVisible, closable: true, width: windowWidth || 577 > 576 ? '85%' : '100%', centered: true, onCancel: onCancel, destroyOnClose: true, footer: null, children: [_jsx("div", { className: "newBlockModalHeader", children: _jsx("div", { className: "newBlockModalHeaderText", children: "Add Template" }) }), _jsxs("div", { className: "newBlockModalBody", children: [_jsx("div", { className: "newBlockModalMenu", children: templateCategories.map(function (category) { return (_jsx("div", { className: "newBlockModalMenuItem", onClick: function () { return scrollParentToChild('template'); }, children: category.name }, category.name)); }) }), _jsx("div", { className: "newBlockModalContent", id: "newBlockModalContent", children: templateCategories.map(function (category) { return (_jsxs("span", { children: [_jsx("div", { className: "newBlockModalSectionHeader", id: "template", children: category.name }), _jsx("div", { className: "newBlockModalCardContainer", children: category.templates.map(function (template, index) {
                                        if (template.identifier === 'TIMEOFF_REQUESTS' &&
                                            !hasTimeOffRequestsAccess)
                                            return;
                                        return (_jsx("div", { className: "newBlockModalCard", onClick: function () { return onClickTemplate(template); }, children: _jsx(PageTemplateCard, __assign({}, template)) }, "newBlockModalCard-".concat(index)));
                                    }) })] }, category.name)); }) })] })] }));
};
export default React.memo(PageTemplateModal);
