var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Timeline, { DateHeader, TimelineHeaders, } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import './ScheduleDayView.scss';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useTimeZone, useWindowSize } from 'src/hooks/component';
import { forEach } from 'lodash';
import { scheduleTableState, scheduleTimeRangeState, scheduleColorCodingSelector, scheduleTimeZoneSelector, tableCollectionIdSelector, scheduleShiftOpenCountState, scheduleTableRowState, scheduleShiftCardBasicInfoSelector, collectionTableSchemaMapSelector, } from 'src/state';
import { isDarkColor } from 'src/utils/Tools/TypeGuard';
import UserTagWithIcon from 'src/components/User/UserTagWithIcon';
import { CollectionTableType } from 'src/shared';
import { getCardColor } from 'src/services';
import ShiftCardDisplayTime from '../../ScheduleCard/ShiftCard/ShiftCardDisplayTime';
import ShiftCardTags from '../../ScheduleCard/ShiftCard/ShiftCardTags';
var ScheduleDayView = function (props) {
    var blockId = props.blockId, openShiftDetailModal = props.openShiftDetailModal, onLoadMore = props.onLoadMore, addNewShift = props.addNewShift;
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var table = useRecoilValue(scheduleTableState(blockId));
    var rowData = useRecoilValue(scheduleTableRowState(blockId));
    var colorCodingSchemaId = useRecoilValue(scheduleColorCodingSelector(blockId));
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var schemaMap = useRecoilValue(collectionTableSchemaMapSelector(collectionId));
    var timezone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var timeZone = useTimeZone(timezone).timeZone;
    var _a = useState([]), selectedItem = _a[0], setSelectedItem = _a[1];
    // remove native pill item title && deselect items
    useEffect(function () {
        var items = document.getElementsByClassName('rct-item');
        if (items) {
            forEach(items, function (item) { return item.removeAttribute('title'); });
        }
        setSelectedItem([]);
    }, [table]);
    var groups = useMemo(function () {
        var groups = [];
        rowData.map(function (row) {
            groups.push({
                id: row.rowKey,
                title: (_jsx(UserTagWithIcon, { name: row.title, avatarProps: {
                        avatar: row.avatarUrl,
                    } })),
            });
        });
        return groups;
    }, [rowData]);
    var generateItems = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            var items = [];
            table.map(function (item) {
                //For date contains cross days shifts, need to push all shifts in resp for all days
                item.columns.map(function (column) {
                    column.cells.map(function (rid) {
                        var _a;
                        var content = snapshot
                            .getLoadable(scheduleShiftCardBasicInfoSelector({
                            type: CollectionTableType.TABLE,
                            collectionId: collectionId,
                            recordId: rid,
                        }))
                            .valueMaybe();
                        if (!content)
                            return;
                        var cardColor = getCardColor(content, colorCodingSchemaId, schemaMap);
                        var openCount = (_a = snapshot
                            .getLoadable(scheduleShiftOpenCountState({
                            type: CollectionTableType.TABLE,
                            collectionId: collectionId,
                            recordId: rid,
                        }))
                            .valueMaybe()) !== null && _a !== void 0 ? _a : 0;
                        items.push({
                            id: rid,
                            group: item.rowKey,
                            title: (_jsxs("div", { className: "flex h-full w-full space-x-2", children: [_jsx("div", { className: "h-full font-semibold", children: _jsx(ShiftCardDisplayTime, { blockId: blockId, recordId: rid }) }), _jsx(ShiftCardTags, { blockId: blockId, recordId: rid, style: { height: '100%' } }), openCount > 1 && (_jsx("div", { className: "OpenCountIndicator", children: openCount }))] })),
                            // manually calculate the time diff to adjust start end time according to timezone change
                            start_time: moment(content.clockIn || content.startAt).add(moment.tz(timeZone).utcOffset() - moment().utcOffset(), 'minutes'),
                            end_time: moment(content.clockOut || content.endAt).add(moment.tz(timeZone).utcOffset() - moment().utcOffset(), 'minutes'),
                            itemProps: {
                                style: {
                                    backgroundColor: cardColor,
                                    borderRadius: '4px',
                                    color: isDarkColor(cardColor) === 'dark' ? 'white' : 'black',
                                },
                            },
                        });
                    });
                });
            });
            return items;
        };
    }, [blockId, collectionId, colorCodingSchemaId, schemaMap, table, timeZone]);
    var _b = useWindowSize().windowHeight, windowHeight = _b === void 0 ? 600 : _b;
    var dayViewScrollHeight = useMemo(function () {
        return windowHeight - 266;
    }, [windowHeight]);
    var handlePaginationScroll = useCallback(function (event) {
        event.stopPropagation();
        var atBottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight <=
            100;
        if (atBottom && onLoadMore) {
            onLoadMore();
        }
    }, [onLoadMore]);
    return (_jsx("div", { className: "DayViewTimeline", id: "DayViewTimeline", style: { maxHeight: onLoadMore ? dayViewScrollHeight : undefined }, onScroll: handlePaginationScroll, children: _jsx(Timeline, { itemRenderer: function (_a) {
                var _b;
                var item = _a.item, itemContext = _a.itemContext, getItemProps = _a.getItemProps;
                var itemStyle = (_b = item.itemProps) === null || _b === void 0 ? void 0 : _b.style;
                return (_jsx("div", __assign({}, getItemProps({
                    style: {
                        backgroundColor: itemStyle === null || itemStyle === void 0 ? void 0 : itemStyle.backgroundColor,
                        color: itemStyle === null || itemStyle === void 0 ? void 0 : itemStyle.color,
                        borderRadius: 4,
                    },
                }), { children: _jsx("div", { className: "rct-item-content", style: { maxHeight: "".concat(itemContext.dimensions.height) }, children: item.title }) })));
            }, groups: groups, items: generateItems(), selected: selectedItem, visibleTimeStart: moment(timeRange[0]).toDate(), visibleTimeEnd: moment(timeRange[0]).startOf('day').add(1, 'day').toDate(), buffer: 1, canMove: false, onItemSelect: function (itemId) {
                setSelectedItem([Number(itemId)]);
                openShiftDetailModal === null || openShiftDetailModal === void 0 ? void 0 : openShiftDetailModal(itemId);
            }, onItemClick: function (itemId) { return openShiftDetailModal === null || openShiftDetailModal === void 0 ? void 0 : openShiftDetailModal(itemId); }, stackItems: true, lineHeight: 40, itemHeightRatio: 0.8, onCanvasClick: function () {
                addNewShift === null || addNewShift === void 0 ? void 0 : addNewShift();
            }, children: _jsx(TimelineHeaders, { className: "DayViewTimeLineHeader sticky text-[10px]", children: _jsx(DateHeader, { labelFormat: "H A" }) }) }) }));
};
export default React.memo(ScheduleDayView);
