var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { GroupsTabIcon, RulesTabIcon, TasksTabIcon } from 'src/utils/icons/TaskIcons/TasksTabIcons';
import { NativePath } from 'src/shared';
import { TaskRuleConditionOperator, TaskTab, TaskTemplateType, } from './interface';
export var TASKS_OVERVIEW_MODAL_PADDING = 64;
export var TASKS_OVERVIEW_HEADER_HEIGHT = 104;
export var TASKS_OVERVIEW_TABS_HEIGHT = 40;
export var TASKS_OVERVIEW_GROUP_CARD_WIDTH = 320;
export var TASKS_OVERVIEW_GROUP_SPACING = 16;
export var DEFAULT_GROUP_ICON = {
    backgroundColor: '#167EE4',
    content: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14"><path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" d="M3.02179 7.68179C2.17768 8.43932 1.24419 9.50509 0.763504 10.7056L2.45584 11.1921L3.66046 13.278C3.99793 11.8323 4.65059 10.5491 5.47699 9.57223"></path><path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" d="M8.52948 9.57521C9.35469 10.5517 10.0064 11.8338 10.3435 13.278L11.5481 11.1921L13.2404 10.7056C12.7606 9.50726 11.8297 8.44318 10.9868 7.68591"></path><path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" d="M7 9.75C9.88 9.75 11.5 8.13 11.5 5.25C11.5 2.37 9.88 0.75 7 0.75C4.12 0.75 2.5 2.37 2.5 5.25C2.5 8.13 4.12 9.75 7 9.75Z"></path><path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" d="M7.11597 3.1778L7.11597 6.87991"></path><path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" d="M5.88193 4.10333L6.19044 4.10333C6.70159 4.10333 7.11597 3.68895 7.11597 3.1778V3.1778"></path><path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" d="M8.3501 6.87994H5.88202"></path></svg>',
};
export var DEFAULT_TASK_TEMPLATE_ICON = {
    backgroundColor: '#00A76F',
    content: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14"><path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" d="M12.5726 1.88053C13.2419 2.57778 13.2192 3.68559 12.522 4.35489C11.8697 4.98106 11.073 5.95613 9.76254 7.58337C8.44296 9.22192 7.24855 10.8432 6.73827 11.7593C6.44729 12.2816 5.90974 12.6193 5.31283 12.6546C4.71592 12.69 4.14229 12.4181 3.79173 11.9336C3.62656 11.7054 3.23537 11.259 2.75705 10.7632C2.28475 10.2736 1.8341 9.84617 1.58615 9.64461C0.836174 9.03497 0.722413 7.93279 1.33205 7.18282C1.94169 6.43285 3.04388 6.31908 3.79385 6.92872C4.1327 7.20417 4.57376 7.62134 4.99723 8.04822C5.61575 7.18926 6.33356 6.26107 7.03662 5.38807C8.3623 3.74196 9.27527 2.61991 10.0982 1.82993C10.7955 1.16063 11.9033 1.18328 12.5726 1.88053Z" clip-rule="evenodd"></path></svg>',
};
export var NEW_TASK_GROUP_ID = 'new_task_group_id';
export var NEW_TASK_TEMPLATE_ID = 'new_task_template_id';
export var NEW_TASK_RULE_ID = 'new_task_rule_id';
export var DEFAULT_TASK_TEMPLATE_INFO = {
    id: NEW_TASK_TEMPLATE_ID,
    name: 'New Task',
    displayId: 'Creating new task...',
    description: '',
    type: TaskTemplateType.EXTERNAL_LINK,
    assignedCount: 0,
    iconInfo: DEFAULT_TASK_TEMPLATE_ICON,
    lastEdited: {},
    isActive: true,
    taskData: {
        type: TaskTemplateType.EXTERNAL_LINK,
        url: '',
    },
    customFieldInfoAndView: {
        customFieldInfo: {
            customFieldValues: [],
            customFieldSchemas: [],
            descriptionVisible: true,
            dueDateVisible: true,
        },
    },
    configuration: {
        autoComplete: true,
        isExternal: true,
        isPublished: true,
    },
};
export var DEFAULT_TASK_GROUP_INFO = {
    id: NEW_TASK_GROUP_ID,
    iconInfo: DEFAULT_GROUP_ICON,
    name: 'New Task Group',
    displayId: 'Creating new task group...',
    description: '',
    assignedCount: 0,
    isActive: true,
    lastEdited: {
        user: {
            rid: '',
            name: '',
        },
        time: '',
    },
    orderedTaskIds: [],
};
export var DEFAULT_TASK_RULE_INFO = {
    id: NEW_TASK_RULE_ID,
    name: 'New Task Rule',
    displayId: 'Creating new task rule...',
    collectionId: '',
    isActive: true,
    conditionData: {
        conditions: [],
        operator: TaskRuleConditionOperator.AND,
    },
    tasks: [],
    configuration: {
        autoComplete: false,
        autoReminder: {
            active: false,
            days: 1,
        },
    },
    lastEdited: {
        user: {
            rid: '',
            name: '',
        },
        time: '',
    },
};
export var TASK_TYPE_OPTIONS = [
    {
        value: TaskTemplateType.PROFILE_DATA,
        name: 'Profile Data',
    },
    {
        value: TaskTemplateType.WORKSPACE_LINK,
        name: 'Workspace',
    },
    {
        value: TaskTemplateType.EXTERNAL_LINK,
        name: 'External Link',
    },
    {
        value: TaskTemplateType.VIEW_ONLY,
        name: 'View Only / No Action',
    },
];
export var TaskTypeTagInfoMap = (_a = {},
    _a[TaskTemplateType.PROFILE_DATA] = {
        name: 'Profile Data',
        backgroundColor: '#CBDAF7',
        textColor: '#135CB3',
    },
    _a[TaskTemplateType.WORKSPACE_LINK] = {
        name: 'Workspace',
        backgroundColor: '#CBECF7',
        textColor: '#1979B2',
    },
    _a[TaskTemplateType.EXTERNAL_LINK] = {
        name: 'External Link',
        backgroundColor: '#F1CBF7',
        textColor: '#8814B3',
    },
    _a[TaskTemplateType.VIEW_ONLY] = {
        name: 'View Only',
        backgroundColor: '#CBF7E0',
        textColor: '#14B37E',
    },
    _a[TaskTemplateType.SIGNING_PARTNER] = {
        name: 'Document',
        backgroundColor: '#F7D8CB',
        textColor: '#B31414',
    },
    _a);
export var TASK_SETTINGS_TABS = [
    {
        key: TaskTab.TEMPLATES,
        name: 'Tasks',
        icon: _jsx(TasksTabIcon, {}),
    },
    {
        key: TaskTab.GROUPS,
        name: 'Groups',
        icon: _jsx(GroupsTabIcon, {}),
    },
    {
        key: TaskTab.RULES,
        name: 'Rules',
        icon: _jsx(RulesTabIcon, {}),
    },
];
export var REQUIRED_NATIVE_SCHEMAS_TO_IGNORE = new Set([
    NativePath.SHIFT_START_AT,
    NativePath.SHIFT_END_AT,
]);
