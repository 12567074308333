import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAccountApi } from 'src/hooks/api';
import { useNavigate } from 'react-router';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { accountDetailState, accountIdState } from 'src/state';
import './ApplyAccountTemplateModal.scss';
import { useAppSession } from 'src/hooks/component';
import IndustryCategorySelectionPage from './IndustryCategorySelectionPage';
import AccountTemplateApplySelectionPage from './AccountTemplateApplySelectionPage';
var LoadingIcon = _jsx(LoadingOutlined, { spin: true });
var ApplyAccountTemplateModal = function (props) {
    var _a;
    var accountInfo = props.accountInfo;
    var navigate = useNavigate();
    var resetAccountDetail = useResetRecoilState(accountDetailState);
    var _b = useAccountApi(), useGetAccountTemplates = _b.useGetAccountTemplates, useGetAccountTemplatesCategoryMetaData = _b.useGetAccountTemplatesCategoryMetaData, useCreateAccountAndApplyTemplate = _b.useCreateAccountAndApplyTemplate;
    var accountTemplateData = useGetAccountTemplates().data;
    var accountTemplateCategoryMetaData = useGetAccountTemplatesCategoryMetaData().data;
    var setAccountId = useSetRecoilState(accountIdState);
    var _c = useState(''), selectedCategory = _c[0], setSelectedCategory = _c[1];
    var _d = useState(0), stepIndex = _d[0], setStepIndex = _d[1];
    var goNextStep = useCallback(function () { return setStepIndex(function (prevIndex) { return prevIndex + 1; }); }, []);
    var goPrevStep = useCallback(function () { return setStepIndex(function (prevIndex) { return prevIndex - 1; }); }, []);
    var _e = useState(false), loadingStatus = _e[0], setLoadingStatus = _e[1];
    var changeLoadingStatus = useCallback(function (status) {
        setLoadingStatus(status);
    }, []);
    var clearAppCache = useAppSession().clearAppCache;
    var onCreateAccountAndApplyTemplate = useCallback(function (type) {
        changeLoadingStatus(true);
        var request = {
            name: accountInfo.name,
            firstName: accountInfo.firstName,
            lastName: accountInfo.lastName,
            phone: accountInfo.phone,
            email: accountInfo.email,
            timezone: accountInfo.timezone,
            templateIdentifier: type,
        };
        useCreateAccountAndApplyTemplate.mutate(request, {
            onSuccess: function (resp) {
                if (resp) {
                    clearAppCache();
                    resetAccountDetail();
                    setAccountId(resp.accountId);
                    navigate('/home', { replace: true });
                    history.go(0);
                }
            },
        });
    }, [
        changeLoadingStatus,
        accountInfo.name,
        accountInfo.firstName,
        accountInfo.lastName,
        accountInfo.phone,
        accountInfo.email,
        accountInfo.timezone,
        useCreateAccountAndApplyTemplate,
        clearAppCache,
        resetAccountDetail,
        setAccountId,
        navigate,
    ]);
    return (_jsxs(Spin, { spinning: loadingStatus, tip: "Loading...", size: "large", indicator: LoadingIcon, style: { position: 'initial' }, children: [stepIndex === 0 && accountTemplateCategoryMetaData && accountTemplateData && (_jsx(IndustryCategorySelectionPage, { categoryMetaDataMap: accountTemplateCategoryMetaData, accountTemplateData: accountTemplateData, selectedCategoryOnChange: function (category) { return setSelectedCategory(category); }, goNextStep: goNextStep })), stepIndex === 1 && (accountTemplateCategoryMetaData === null || accountTemplateCategoryMetaData === void 0 ? void 0 : accountTemplateCategoryMetaData[selectedCategory]) && (_jsx(AccountTemplateApplySelectionPage, { templateCards: ((_a = accountTemplateData === null || accountTemplateData === void 0 ? void 0 : accountTemplateData[selectedCategory]) === null || _a === void 0 ? void 0 : _a.templates) || [], categoryMetaData: accountTemplateCategoryMetaData === null || accountTemplateCategoryMetaData === void 0 ? void 0 : accountTemplateCategoryMetaData[selectedCategory], onCreateAccountAndApplyTemplate: onCreateAccountAndApplyTemplate, goPrevStep: goPrevStep }))] }));
};
export default React.memo(ApplyAccountTemplateModal);
