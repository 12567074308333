import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAccountApi } from 'src/hooks/api';
import { ProfileTaskSchemaType } from '../../interface';
import { getFieldDisplay } from '../util';
var ProfileTaskSchemaMobilePreview = function (_a) {
    var taskData = _a.taskData, templateId = _a.templateId;
    var collectionId = taskData.collectionId;
    var useGetSchemaColumnsInCollection = useAccountApi().useGetSchemaColumnsInCollection;
    var schemas = useGetSchemaColumnsInCollection({
        collectionId: collectionId !== null && collectionId !== void 0 ? collectionId : '',
        options: {
            enabled: !!collectionId,
        },
    }).data;
    var mobilePreviewTaskSchemas = taskData.taskSchemas.filter(function (schema) { return schema.taskSchemaType !== ProfileTaskSchemaType.HIDDEN; });
    return (_jsx("div", { className: "mt-3 ml-5 mr-5", children: mobilePreviewTaskSchemas.map(function (value) {
            var _a;
            var schema = (_a = schemas === null || schemas === void 0 ? void 0 : schemas.columns) === null || _a === void 0 ? void 0 : _a.find(function (column) {
                return column.id === value.schemaId;
            });
            if (!schema)
                return null;
            var isRequired = value.taskSchemaType === ProfileTaskSchemaType.REQUIRED;
            return (_jsxs("div", { className: "mt-3", children: [_jsxs("div", { className: "text-gray-400 font-semibold mb-1", children: [_jsx("span", { children: schema.name }), isRequired && _jsx("span", { className: "ml-0.5 text-red-500", children: "*" })] }), _jsx("div", { className: "text-gray-500 mb-3", children: getFieldDisplay({
                            schemaInfo: schema,
                            taskCollectionId: collectionId,
                            templateId: templateId,
                            hideInputBorder: value.taskSchemaType === ProfileTaskSchemaType.LOCKED,
                        }) })] }, schema.id));
        }) }));
};
export default React.memo(ProfileTaskSchemaMobilePreview);
