var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DownOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown } from 'antd';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
var MobileTrackingDropdown = function (_a) {
    var defaultOptions = _a.defaultOptions, onChange = _a.onChange, showAlways = _a.showAlways;
    var _b = useState(defaultOptions), trackingOptions = _b[0], setTrackingOptions = _b[1];
    var handleSaveTrackingOptions = useCallback(function () {
        onChange(trackingOptions);
    }, [onChange, trackingOptions]);
    var items = useMemo(function () {
        return Object.entries(trackingOptions).map(function (_a) {
            var key = _a[0], value = _a[1];
            if (key === 'enabled')
                return null;
            if (!showAlways && key === 'always')
                return null;
            var checked = showAlways ? value || trackingOptions.always : value;
            return {
                key: key,
                label: (_jsxs("div", { className: "flex items-center", onClick: function (e) { return e.stopPropagation(); }, children: [_jsx("span", { children: _.startCase(key) }), _jsx(Checkbox, { checked: checked, className: "MobileSettingCheckBox ml-auto", onChange: function (e) {
                                setTrackingOptions(function (prev) {
                                    var _a;
                                    return (__assign(__assign({}, prev), (_a = {}, _a['always'] = showAlways ? e.target.checked : prev.always, _a[key] = e.target.checked, _a)));
                                });
                            } })] })),
            };
        });
    }, [showAlways, trackingOptions]);
    return (_jsx(Dropdown, { menu: { items: items }, trigger: ['click'], overlayStyle: { width: '150px' }, onOpenChange: function (visible) {
            if (!visible) {
                handleSaveTrackingOptions();
            }
        }, className: "MobileSettingDropDown", children: _jsxs("div", { className: "rounded bg-gray-50 px-3 py-1 font-medium hover:cursor-pointer", children: ["Options ", _jsx(DownOutlined, {})] }) }));
};
export default MobileTrackingDropdown;
